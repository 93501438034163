import { createElement, isNullOrUndefined, addClass } from '@syncfusion/ej2-base';
/**
 * Edit render module is used to render grid edit row.
 * @hidden
 */
var InlineEditRender = /** @class */ (function () {
    /**
     * Constructor for render module
     */
    function InlineEditRender(parent) {
        this.parent = parent;
    }
    InlineEditRender.prototype.addNew = function (elements, args) {
        var mTbody;
        var tbody;
        if (this.parent.frozenRows) {
            tbody = this.parent.getHeaderContent().querySelector('tbody');
        }
        else {
            tbody = this.parent.getContentTable().querySelector('tbody');
        }
        args.row = createElement('tr', { className: 'e-row e-addedrow' });
        if (tbody.querySelector('.e-emptyrow')) {
            tbody.querySelector('.e-emptyrow').classList.add('e-hide');
        }
        tbody.insertBefore(args.row, tbody.firstChild);
        args.row.appendChild(this.getEditElement(elements, false));
        if (this.parent.getFrozenColumns()) {
            var mEle = this.renderMovableform(args.row);
            if (this.parent.frozenRows) {
                mTbody = this.parent.getHeaderContent().querySelector('.e-movableheader').querySelector('tbody');
            }
            else {
                mTbody = this.parent.getContent().querySelector('.e-movablecontent').querySelector('tbody');
            }
            mTbody.insertBefore(mEle, mTbody.firstChild);
            args.row.querySelector('.e-normaledit').setAttribute('colspan', this.parent.getVisibleFrozenColumns() + '');
            mEle.setAttribute('colspan', '' + (this.parent.getVisibleColumns().length - this.parent.getVisibleFrozenColumns()));
        }
    };
    InlineEditRender.prototype.renderMovableform = function (ele) {
        var mEle = ele.cloneNode(true);
        this.renderMovable(ele, mEle);
        mEle.querySelector('colgroup').innerHTML = this.parent.getHeaderContent()
            .querySelector('.e-movableheader').querySelector('colgroup').innerHTML;
        return mEle;
    };
    InlineEditRender.prototype.updateFreezeEdit = function (row, td) {
        if (this.parent.getFrozenColumns()) {
            var idx = parseInt(row.getAttribute('aria-rowindex'), 10);
            var fCont = this.parent.getContent().querySelector('.e-frozencontent').querySelector('tbody');
            var mCont = this.parent.getContent().querySelector('.e-movablecontent').querySelector('tbody');
            var fHdr = this.parent.getHeaderContent().querySelector('.e-frozenheader').querySelector('tbody');
            var mHdr = this.parent.getHeaderContent().querySelector('.e-movableheader').querySelector('tbody');
            if (this.parent.frozenRows && idx >= this.parent.frozenRows) {
                idx -= this.parent.frozenRows;
            }
            if (fCont.contains(row)) {
                td = td.concat([].slice.call(mCont.children[idx].querySelectorAll('td.e-rowcell')));
            }
            else if (mCont.contains(row)) {
                td = td.concat([].slice.call(fCont.children[idx].querySelectorAll('td.e-rowcell')));
            }
            else if (fHdr.contains(row)) {
                td = td.concat([].slice.call(mHdr.children[idx].querySelectorAll('td.e-rowcell')));
            }
            else if (mHdr.contains(row)) {
                td = td.concat([].slice.call(fHdr.children[idx].querySelectorAll('td.e-rowcell')));
            }
        }
        return td;
    };
    InlineEditRender.prototype.update = function (elements, args) {
        var tdElement = [].slice.call(args.row.querySelectorAll('td.e-rowcell'));
        args.row.innerHTML = '';
        tdElement = this.updateFreezeEdit(args.row, tdElement);
        args.row.appendChild(this.getEditElement(elements, true, tdElement));
        args.row.classList.add('e-editedrow');
        this.refreshFreezeEdit(args.row);
    };
    InlineEditRender.prototype.refreshFreezeEdit = function (row) {
        var td = row.firstChild;
        var fCls;
        var cont;
        var idx = parseInt(row.getAttribute('aria-rowindex'), 10);
        if (this.parent.getFrozenColumns()) {
            if (idx < this.parent.frozenRows) {
                cont = this.parent.getHeaderContent();
                fCls = '.e-frozenheader';
            }
            else {
                cont = this.parent.getContent();
                fCls = '.e-frozencontent';
            }
            var mTd = td.cloneNode(true);
            var fRows = void 0;
            if (cont.querySelector(fCls).contains(row)) {
                fRows = this.parent.getMovableRowByIndex(idx);
                this.updateFrozenCont(fRows, td, mTd);
            }
            else {
                fRows = this.parent.getRowByIndex(idx);
                this.updateFrozenCont(fRows, mTd, td);
            }
            fRows.appendChild(mTd);
            fRows.classList.add('e-editedrow');
        }
    };
    InlineEditRender.prototype.updateFrozenCont = function (row, ele, mEle) {
        row.innerHTML = '';
        this.renderMovable(ele, mEle);
        mEle.querySelector('colgroup').innerHTML = this.parent.getHeaderContent()
            .querySelector('.e-movableheader').querySelector('colgroup').innerHTML;
        ele.setAttribute('colspan', this.parent.getVisibleFrozenColumns() + '');
        mEle.setAttribute('colspan', this.parent.getColumns().length - this.parent.getFrozenColumns() + '');
    };
    InlineEditRender.prototype.renderMovable = function (ele, mEle) {
        var frzCols = this.parent.getFrozenColumns();
        for (var i = 0; i < frzCols; i++) {
            mEle.querySelector('tr').removeChild(mEle.querySelector('tr').children[0]);
        }
        for (var i = frzCols, len = ele.querySelector('tr').childElementCount; i < len; i++) {
            ele.querySelector('tr').removeChild(ele.querySelector('tr').children[ele.querySelector('tr').childElementCount - 1]);
        }
    };
    InlineEditRender.prototype.getEditElement = function (elements, isEdit, tdElement) {
        var gObj = this.parent;
        var gLen = 0;
        var isDetail = !isNullOrUndefined(gObj.detailTemplate) || !isNullOrUndefined(gObj.childGrid) ? 1 : 0;
        if (gObj.allowGrouping) {
            gLen = gObj.groupSettings.columns.length;
        }
        var td = createElement('td', {
            className: 'e-editcell e-normaledit',
            attrs: { colspan: (gObj.getVisibleColumns().length - gObj.getVisibleFrozenColumns() + gLen + isDetail).toString() }
        });
        var form = createElement('form', { id: gObj.element.id + 'EditForm', className: 'e-gridform' });
        var table = createElement('table', { className: 'e-table e-inline-edit', attrs: { cellspacing: '0.25' } });
        table.appendChild(gObj.getContentTable().querySelector('colgroup').cloneNode(true));
        var tbody = createElement('tbody');
        var tr = createElement('tr');
        var i = 0;
        if (isDetail) {
            tr.insertBefore(createElement('td', { className: 'e-detailrowcollapse' }), tr.firstChild);
        }
        while (i < gLen) {
            tr.appendChild(createElement('td', { className: 'e-indentcell' }));
            i++;
        }
        var m = 0;
        i = 0;
        while ((isEdit && m < tdElement.length && i < gObj.getColumns().length) || i < gObj.getColumns().length) {
            var span = isEdit ? tdElement[m].getAttribute('colspan') : null;
            var col = gObj.getColumns()[i];
            var td_1 = createElement('td', {
                className: 'e-rowcell', attrs: { style: 'text-align:' + (col.textAlign ? col.textAlign : ''), 'colspan': span ? span : '' }
            });
            if (col.visible) {
                td_1.appendChild(elements[col.uid]);
                if (col.editType === 'booleanedit') {
                    td_1.classList.add('e-boolcell');
                }
                else if (col.commands || col.commandsTemplate) {
                    addClass([td_1], 'e-unboundcell');
                }
            }
            else {
                td_1.classList.add('e-hide');
            }
            tr.appendChild(td_1);
            i = span ? i + parseInt(span, 10) : i + 1;
            m++;
        }
        tbody.appendChild(tr);
        table.appendChild(tbody);
        form.appendChild(table);
        td.appendChild(form);
        return td;
    };
    InlineEditRender.prototype.removeEventListener = function () {
        //To destroy the renderer
    };
    return InlineEditRender;
}());
export { InlineEditRender };
