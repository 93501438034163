var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventHandler, getValue, closest, isNullOrUndefined } from '@syncfusion/ej2-base';
import { addClass, removeClass, extend, Browser } from '@syncfusion/ej2-base';
import { CellType } from '../base/enum';
import * as event from '../base/constant';
/**
 * FocusStrategy class
 * @hidden
 */
var FocusStrategy = /** @class */ (function () {
    function FocusStrategy(parent) {
        this.currentInfo = {};
        this.oneTime = true;
        this.swap = {};
        this.forget = true;
        this.skipFocus = true;
        this.focusByClick = false;
        this.prevIndexes = {};
        this.parent = parent;
        this.addEventListener();
    }
    FocusStrategy.prototype.focusCheck = function (e) {
        var target = e.target;
        this.focusByClick = true;
        this.skipFocus = target.classList.contains('e-grid');
    };
    FocusStrategy.prototype.onFocus = function () {
        if (this.parent.isDestroyed || Browser.isDevice || this.parent.enableVirtualization) {
            return;
        }
        this.setActive(this.parent.frozenRows === 0, this.parent.frozenColumns !== 0);
        var current = this.getContent().matrix.get(0, -1, [0, 1], null, this.getContent().validator());
        this.getContent().matrix.select(current[0], current[1]);
        if (this.skipFocus) {
            this.focus();
            this.skipFocus = false;
        }
    };
    FocusStrategy.prototype.passiveFocus = function (e) {
        if (this.parent.isDestroyed) {
            return;
        }
        if (e.target && e.target.classList.contains('e-detailcell')) {
            this.currentInfo.skipAction = false;
            addClass([this.currentInfo.element], ['e-focused', 'e-focus']);
        }
    };
    FocusStrategy.prototype.onBlur = function (e) {
        if ((this.parent.isEdit || e && (!e.relatedTarget || closest(e.relatedTarget, '.e-grid')))) {
            return;
        }
        this.removeFocus();
        this.skipFocus = false;
        this.currentInfo.skipAction = false;
        if (this.getContent().getFocusInfo().elementToFocus) {
            this.getContent().getFocusInfo().elementToFocus.tabIndex = 0;
        }
    };
    FocusStrategy.prototype.onClick = function (e, force) {
        var isContent = !isNullOrUndefined(closest(e.target, '.e-gridcontent'));
        var isHeader = !isNullOrUndefined(closest(e.target, '.e-gridheader'));
        isContent = isContent && isHeader ? !isContent : isContent;
        var isFrozen = !isNullOrUndefined(closest(e.target, '.e-frozencontent')) ||
            !isNullOrUndefined(closest(e.target, '.e-frozenheader'));
        if (!isContent && isNullOrUndefined(closest(e.target, '.e-gridheader')) ||
            e.target.classList.contains('e-content')) {
            return;
        }
        this.setActive(isContent, isFrozen);
        if (!isContent && isNullOrUndefined(closest(e.target, '.e-gridheader')) ||
            e.target.classList.contains('e-filtermenudiv')) {
            this.clearOutline();
            return;
        }
        var beforeArgs = { cancel: false, byKey: false, byClick: !isNullOrUndefined(e.target), clickArgs: e };
        this.parent.notify(event.beforeCellFocused, beforeArgs);
        if (beforeArgs.cancel || closest(e.target, '.e-inline-edit')) {
            return;
        }
        this.setActive(isContent, isFrozen);
        if (this.getContent()) {
            var returnVal = this.getContent().onClick(e, force);
            if (returnVal === false) {
                return;
            }
            this.focus();
        }
    };
    FocusStrategy.prototype.onKeyPress = function (e) {
        if (this.skipOn(e)) {
            return;
        }
        var beforeArgs = { cancel: false, byKey: true, byClick: false, keyArgs: e };
        this.parent.notify(event.beforeCellFocused, beforeArgs);
        if (beforeArgs.cancel) {
            return;
        }
        var bValue = this.getContent().matrix.current;
        this.currentInfo.outline = true;
        var swapInfo = this.getContent().jump(e.action, bValue);
        this.swap = swapInfo;
        if (swapInfo.swap) {
            this.setActive(!swapInfo.toHeader, swapInfo.toFrozen);
            this.getContent().matrix.current = this.getContent().getNextCurrent(bValue, swapInfo, this.active, e.action);
            this.prevIndexes = {};
        }
        this.setActiveByKey(e.action, this.getContent());
        var returnVal = this.getContent().onKeyPress(e);
        if (returnVal === false) {
            this.clearIndicator();
            return;
        }
        e.preventDefault();
        this.focus(e);
    };
    FocusStrategy.prototype.skipOn = function (e) {
        var target = e.target;
        if (!target) {
            return false;
        }
        if (this.currentInfo.skipAction) {
            this.clearIndicator();
            return true;
        }
        if (['pageUp', 'pageDown'].indexOf(e.action) > -1) {
            this.clearIndicator();
            return true;
        }
        return (e.action === 'delete'
            || (this.parent.editSettings.mode !== 'Batch' && (this.parent.isEdit || ['insert', 'f2'].indexOf(e.action) > -1))
            || (closest(document.activeElement, '.e-filterbarcell') !== null ||
                closest(document.activeElement, '#' + this.parent.element.id + '_searchbar') !== null
                    && ['enter', 'leftArrow', 'rightArrow',
                        'shiftLeft', 'shiftRight', 'ctrlPlusA'].indexOf(e.action) > -1)
            || (closest(target, '.e-gridcontent') === null && closest(target, '.e-gridheader') === null)
            || (e.action === 'space' && (!target.classList.contains('e-gridchkbox') && closest(target, '.e-gridchkbox') === null
                && closest(target, '.e-headerchkcelldiv') === null)));
    };
    FocusStrategy.prototype.getFocusedElement = function () {
        return this.currentInfo.elementToFocus;
    };
    FocusStrategy.prototype.getContent = function () {
        return this.active || this.content;
    };
    FocusStrategy.prototype.setActive = function (content, isFrozen) {
        this.active = content ? isFrozen ? this.fContent : this.content :
            isFrozen ? this.fHeader : this.header;
    };
    FocusStrategy.prototype.setFocusedElement = function (element) {
        var _this = this;
        this.currentInfo.elementToFocus = element;
        setTimeout(function () { return _this.currentInfo.elementToFocus.focus(); }, 0);
    };
    FocusStrategy.prototype.focus = function (e) {
        this.removeFocus();
        this.addFocus(this.getContent().getFocusInfo(), e);
    };
    FocusStrategy.prototype.removeFocus = function (e) {
        if (!this.currentInfo.element) {
            return;
        }
        removeClass([this.currentInfo.element, this.currentInfo.elementToFocus], ['e-focused', 'e-focus']);
        this.currentInfo.element.tabIndex = -1;
    };
    FocusStrategy.prototype.addFocus = function (info, e) {
        this.currentInfo = info;
        this.currentInfo.outline = info.outline && !isNullOrUndefined(e);
        if (!info.element) {
            return;
        }
        var isFocused = info.elementToFocus.classList.contains('e-focus');
        if (isFocused) {
            return;
        }
        if (this.currentInfo.outline) {
            addClass([info.element], ['e-focused']);
        }
        addClass([info.elementToFocus], ['e-focus']);
        info.element.tabIndex = 0;
        if (!isFocused) {
            this.setFocusedElement(info.elementToFocus);
        }
        this.parent.notify(event.cellFocused, {
            element: info.elementToFocus,
            parent: info.element,
            indexes: this.getContent().matrix.current,
            byKey: !isNullOrUndefined(e),
            byClick: isNullOrUndefined(e),
            keyArgs: e,
            isJump: this.swap.swap,
            container: this.getContent().getInfo(e),
            outline: !isNullOrUndefined(e),
            swapInfo: this.swap
        });
        var _a = this.getContent().matrix.current, rowIndex = _a[0], cellIndex = _a[1];
        this.prevIndexes = { rowIndex: rowIndex, cellIndex: cellIndex };
        this.focusByClick = false;
    };
    FocusStrategy.prototype.refreshMatrix = function (content) {
        var _this = this;
        return function (e) {
            if (content && (e.args && e.args.isFrozen) && !_this.fContent) {
                _this.fContent = new FixedContentFocus(_this.parent);
            }
            else if (content && !_this.content) {
                _this.content = new ContentFocus(_this.parent);
            }
            if (!content && (e.args && e.args.isFrozen) && !_this.fHeader) {
                _this.fHeader = new FixedHeaderFocus(_this.parent);
            }
            else if (!content && !_this.header) {
                _this.header = new HeaderFocus(_this.parent);
            }
            var cFocus = content ? (e.args && e.args.isFrozen) ? _this.fContent : _this.content :
                (e.args && e.args.isFrozen) ? _this.fHeader : _this.header;
            var rows = content ? e.rows.slice(_this.parent.frozenRows) : e.rows;
            var updateRow = content ? e.rows.slice(0, _this.parent.frozenRows) : e.rows;
            var matrix = cFocus.matrix.generate(updateRow, cFocus.selector);
            cFocus.matrix.generate(rows, cFocus.selector);
            cFocus.generateRows(updateRow, { matrix: matrix, handlerInstance: (e.args && e.args.isFrozen) ? _this.fHeader : _this.header });
            if (!Browser.isDevice && !_this.focusByClick && e && e.args && e.args.requestType === 'paging') {
                _this.skipFocus = false;
                _this.parent.element.focus();
            }
        };
    };
    FocusStrategy.prototype.addEventListener = function () {
        var _this = this;
        if (this.parent.isDestroyed) {
            return;
        }
        EventHandler.add(this.parent.element, 'mousedown', this.focusCheck, this);
        EventHandler.add(this.parent.element, 'focus', this.onFocus, this);
        this.parent.element.addEventListener('focus', this.passiveHandler = function (e) { return _this.passiveFocus(e); }, true);
        EventHandler.add(this.parent.element, 'focusout', this.onBlur, this);
        this.parent.on(event.keyPressed, this.onKeyPress, this);
        this.parent.on(event.click, this.onClick, this);
        this.parent.on(event.contentReady, this.refreshMatrix(true), this);
        this.parent.on(event.headerRefreshed, this.refreshMatrix(), this);
        this.parent.on('close-edit', this.restoreFocus, this);
        ['start-edit', 'start-add'].forEach(function (evt) { return _this.parent.on(evt, _this.clearIndicator, _this); });
        ['sorting'].forEach(function (action) { return _this.parent.on(action + "-complete", _this.restoreFocus, _this); });
        this.parent.on(event.batchAdd, this.refreshMatrix(true), this);
        this.parent.on(event.batchCancel, this.refreshMatrix(true), this);
        this.parent.on(event.batchDelete, this.refreshMatrix(true), this);
        this.parent.on(event.detailDataBound, this.refreshMatrix(true), this);
        this.parent.on(event.onEmpty, this.refreshMatrix(true), this);
        this.parent.on(event.cellFocused, this.internalCellFocus, this);
    };
    FocusStrategy.prototype.removeEventListener = function () {
        var _this = this;
        if (this.parent.isDestroyed) {
            return;
        }
        EventHandler.remove(this.parent.element, 'mousedown', this.focusCheck);
        EventHandler.remove(this.parent.element, 'focus', this.onFocus);
        EventHandler.remove(this.parent.element, 'focusout', this.onBlur);
        this.parent.element.removeEventListener('focus', this.passiveHandler, true);
        this.parent.off(event.keyPressed, this.onKeyPress);
        this.parent.off(event.click, this.onClick);
        this.parent.off(event.contentReady, this.refreshMatrix(true));
        this.parent.off(event.headerRefreshed, this.refreshMatrix());
        this.parent.off('close-edit', this.restoreFocus);
        ['start-edit', 'start-add'].forEach(function (evt) { return _this.parent.off(evt, _this.clearOutline); });
        ['sorting'].forEach(function (action) { return _this.parent.off(action + "-complete", _this.restoreFocus); });
        this.parent.off(event.batchAdd, this.refreshMatrix(true));
        this.parent.off(event.batchDelete, this.refreshMatrix(true));
        this.parent.off(event.batchCancel, this.refreshMatrix(true));
        this.parent.off(event.detailDataBound, this.refreshMatrix(true));
        this.parent.off(event.onEmpty, this.refreshMatrix(true));
        this.parent.off(event.cellFocused, this.internalCellFocus);
    };
    FocusStrategy.prototype.destroy = function () {
        this.removeEventListener();
    };
    FocusStrategy.prototype.restoreFocus = function () {
        this.addFocus(this.getContent().getFocusInfo());
    };
    FocusStrategy.prototype.clearOutline = function () {
        this.getContent().matrix.current = this.getContent().matrix.get(0, -1, [0, 1], 'downArrow', this.getContent().validator());
        this.clearIndicator();
    };
    FocusStrategy.prototype.clearIndicator = function () {
        if (!this.currentInfo.element || !this.currentInfo.elementToFocus) {
            return;
        }
        removeClass([this.currentInfo.element, this.currentInfo.elementToFocus], ['e-focus', 'e-focused']);
    };
    FocusStrategy.prototype.getPrevIndexes = function () {
        var forget = this.forget;
        this.forget = false;
        return forget ? { rowIndex: null, cellIndex: null } : this.prevIndexes;
    };
    FocusStrategy.prototype.forgetPrevious = function () {
        this.forget = true;
    };
    FocusStrategy.prototype.setActiveByKey = function (action, active) {
        var _this = this;
        if (this.parent.frozenColumns === 0 && this.parent.frozenRows === 0) {
            return;
        }
        var info;
        var actions = {
            'home': function () { return ({ toHeader: !info.isContent, toFrozen: true }); },
            'end': function () { return ({ toHeader: !info.isContent, toFrozen: false }); },
            'ctrlHome': function () { return ({ toHeader: true, toFrozen: _this.parent.frozenColumns !== 0 }); },
            'ctrlEnd': function () { return ({ toHeader: false, toFrozen: false }); }
        };
        if (!(action in actions)) {
            return;
        }
        info = active.getInfo();
        var swap = actions[action]();
        this.setActive(!swap.toHeader, swap.toFrozen);
        this.getContent().matrix.current = active.matrix.current;
    };
    FocusStrategy.prototype.internalCellFocus = function (e) {
        if (!(e.byKey && e.container.isContent && e.keyArgs.action === 'enter'
            && (e.parent.classList.contains('e-detailcell') ||
                e.parent.classList.contains('e-unboundcell') || e.parent.classList.contains('e-templatecell')))) {
            return;
        }
        this.clearIndicator();
        var focusEle = this.getContent().getFocusable(this.getFocusedElement());
        this.setFocusedElement(focusEle);
        this.currentInfo.skipAction = true;
    };
    return FocusStrategy;
}());
export { FocusStrategy };
/**
 * Create matrix from row collection which act as mental model for cell navigation
 * @hidden
 */
var Matrix = /** @class */ (function () {
    function Matrix() {
        this.matrix = [];
        this.current = [];
    }
    Matrix.prototype.set = function (rowIndex, columnIndex, allow) {
        rowIndex = Math.max(0, Math.min(rowIndex, this.rows));
        columnIndex = Math.max(0, Math.min(columnIndex, this.columns));
        this.matrix[rowIndex] = this.matrix[rowIndex] || [];
        this.matrix[rowIndex][columnIndex] = allow ? 1 : 0;
    };
    Matrix.prototype.get = function (rowIndex, columnIndex, navigator, action, validator) {
        var tmp = columnIndex;
        if (rowIndex + navigator[0] < 0) {
            return [rowIndex, columnIndex];
        }
        rowIndex = Math.max(0, Math.min(rowIndex + navigator[0], this.rows));
        columnIndex = Math.max(0, Math.min(columnIndex + navigator[1], this.matrix[rowIndex].length - 1));
        if (tmp + navigator[1] > this.matrix[rowIndex].length - 1 && validator(rowIndex, columnIndex, action)) {
            return [rowIndex, tmp];
        }
        var first = this.first(this.matrix[rowIndex], columnIndex, navigator, true, action);
        columnIndex = first === null ? tmp : first;
        var val = getValue(rowIndex + "." + columnIndex, this.matrix);
        return this.inValid(val) || !validator(rowIndex, columnIndex, action) ?
            this.get(rowIndex, tmp, navigator, action, validator) : [rowIndex, columnIndex];
    };
    Matrix.prototype.first = function (vector, index, navigator, moveTo, action) {
        if (((index < 0 || index === vector.length) && this.inValid(vector[index])
            && (action !== 'upArrow' && action !== 'downArrow')) || !vector.some(function (v) { return v === 1; })) {
            return null;
        }
        return !this.inValid(vector[index]) ? index :
            this.first(vector, (['upArrow', 'downArrow', 'shiftUp', 'shiftDown'].indexOf(action) !== -1) ? moveTo ? 0 : ++index : index + navigator[1], navigator, false, action);
    };
    Matrix.prototype.select = function (rowIndex, columnIndex) {
        rowIndex = Math.max(0, Math.min(rowIndex, this.rows));
        columnIndex = Math.max(0, Math.min(columnIndex, this.matrix[rowIndex].length - 1));
        this.current = [rowIndex, columnIndex];
    };
    Matrix.prototype.generate = function (rows, selector) {
        var _this = this;
        this.rows = rows.length - 1;
        this.matrix = [];
        rows.forEach(function (row, rIndex) {
            var cells = row.cells.filter(function (c) { return c.isSpanned !== true; });
            _this.columns = Math.max(cells.length - 1, _this.columns | 0);
            cells.forEach(function (cell, cIndex) {
                _this.set(rIndex, cIndex, selector(row, cell));
            });
        });
        return this.matrix;
    };
    Matrix.prototype.inValid = function (value) {
        return value === 0 || value === undefined;
    };
    return Matrix;
}());
export { Matrix };
/**
 * @hidden
 */
var ContentFocus = /** @class */ (function () {
    function ContentFocus(parent) {
        var _this = this;
        this.matrix = new Matrix();
        this.parent = parent;
        this.keyActions = {
            'rightArrow': [0, 1],
            'tab': [0, 1],
            'leftArrow': [0, -1],
            'shiftTab': [0, -1],
            'upArrow': [-1, 0],
            'downArrow': [1, 0],
            'shiftUp': [-1, 0],
            'shiftDown': [1, 0],
            'shiftRight': [0, 1],
            'shiftLeft': [0, -1],
            'enter': [1, 0],
            'shiftEnter': [-1, 0]
        };
        this.indexesByKey = function (action) {
            var opt = {
                'home': [_this.matrix.current[0], -1, 0, 1],
                'end': [_this.matrix.current[0], _this.matrix.columns + 1, 0, -1],
                'ctrlHome': [0, -1, 0, 1],
                'ctrlEnd': [_this.matrix.rows, _this.matrix.columns + 1, 0, -1]
            };
            return opt[action] || null;
        };
    }
    ContentFocus.prototype.getTable = function () {
        return (this.parent.frozenColumns ?
            this.parent.getContent().querySelector('.e-movablecontent .e-table') :
            this.parent.getContentTable());
    };
    ContentFocus.prototype.onKeyPress = function (e) {
        var navigator = this.keyActions[e.action];
        var current = this.getCurrentFromAction(e.action, navigator, e.action in this.keyActions, e);
        if (!current) {
            return;
        }
        if ((['tab', 'shiftTab'].indexOf(e.action) > -1 && this.matrix.current || []).toString() === current.toString()) {
            if (current.toString() === [this.matrix.rows, this.matrix.columns].toString() ||
                current.toString() === [0, 0].toString()) {
                return false;
            }
        }
        this.matrix.select(current[0], current[1]);
    };
    ContentFocus.prototype.getCurrentFromAction = function (action, navigator, isPresent, e) {
        if (navigator === void 0) { navigator = [0, 0]; }
        if (!isPresent && !this.indexesByKey(action)) {
            return null;
        }
        if (!this.shouldFocusChange(e)) {
            return this.matrix.current;
        }
        var _a = this.indexesByKey(action) || this.matrix.current.concat(navigator), rowIndex = _a[0], cellIndex = _a[1], rN = _a[2], cN = _a[3];
        var current = this.matrix.get(rowIndex, cellIndex, [rN, cN], action, this.validator());
        return current;
    };
    ContentFocus.prototype.onClick = function (e, force) {
        var target = e.target;
        target = (target.classList.contains('e-rowcell') ? target : closest(target, 'td'));
        target = target ? target : closest(e.target, 'td.e-detailrowcollapse')
            || closest(e.target, 'td.e-detailrowexpand');
        target = closest(e.target, 'td.e-detailcell') ?
            isNullOrUndefined(closest(closest(e.target, '.e-grid'), 'td.e-detailcell')) ? null : target : target;
        target = target && closest(target, 'table').classList.contains('e-table') ? target : null;
        if (!target) {
            return false;
        }
        var _a = [target.parentElement.rowIndex, target.cellIndex], rowIndex = _a[0], cellIndex = _a[1];
        var _b = this.matrix.current, oRowIndex = _b[0], oCellIndex = _b[1];
        var val = getValue(rowIndex + "." + cellIndex, this.matrix.matrix);
        if (this.matrix.inValid(val) || (!force && oRowIndex === rowIndex && oCellIndex === cellIndex)) {
            return false;
        }
        this.matrix.select(rowIndex, cellIndex);
    };
    ContentFocus.prototype.getFocusInfo = function () {
        var info = {};
        var _a = this.matrix.current, _b = _a[0], rowIndex = _b === void 0 ? 0 : _b, _c = _a[1], cellIndex = _c === void 0 ? 0 : _c;
        this.matrix.current = [rowIndex, cellIndex];
        info.element = this.getTable().rows[rowIndex].cells[cellIndex];
        if (!info.element) {
            return info;
        }
        info.elementToFocus = !info.element.classList.contains('e-unboundcell') && !info.element.classList.contains('e-detailcell')
            ? this.getFocusable(info.element) : info.element;
        info.outline = true;
        return info;
    };
    ContentFocus.prototype.getFocusable = function (element) {
        var query = 'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])';
        if (this.parent.isEdit) {
            query = 'input:not([type="hidden"]), select:not([aria-hidden="true"]), textarea';
        }
        var child = [].slice.call(element.querySelectorAll(query));
        /* Select the first focusable child element
         * if no child found then select the cell itself.
         * if Grid is in editable state, check for editable control inside child.
         */
        return child.length ? child[0] : element;
    };
    ContentFocus.prototype.selector = function (row, cell) {
        var types = [CellType.Expand, CellType.GroupCaption, CellType.CaptionSummary, CellType.GroupSummary];
        return ((row.isDataRow && cell.visible && (cell.isDataCell || cell.isTemplate))
            || (row.isDataRow && cell.cellType === CellType.DetailExpand)
            || (!row.isDataRow && types.indexOf(cell.cellType) > -1)
            || (cell.column && cell.column.type === 'checkbox')
            || (cell.cellType === CellType.CommandColumn))
            && !(row.edit === 'delete' && row.isDirty);
    };
    ContentFocus.prototype.jump = function (action, current) {
        var frozenSwap = this.parent.frozenColumns > 0 &&
            ((action === 'leftArrow' || action === 'shiftTab') && current[1] === 0);
        var enterFrozen = this.parent.frozenRows !== 0 && action === 'shiftEnter';
        var info = {
            swap: ((action === 'upArrow' || enterFrozen) && current[0] === 0) || frozenSwap,
            toHeader: (action === 'upArrow' || enterFrozen) && current[0] === 0,
            toFrozen: frozenSwap
        };
        return info;
    };
    ContentFocus.prototype.getNextCurrent = function (previous, swap, active, action) {
        if (previous === void 0) { previous = []; }
        var current = [];
        if (action === 'rightArrow' || action === 'tab') {
            current[0] = previous[0];
            current[1] = -1;
        }
        else if (action === 'downArrow' || action === 'enter') {
            current[0] = -1;
            current[1] = previous[1];
        }
        return current;
    };
    ContentFocus.prototype.generateRows = function (rows, optionals) {
        var matrix = optionals.matrix, handlerInstance = optionals.handlerInstance;
        var len = handlerInstance.matrix.matrix.length;
        var defaultLen = this.parent.allowFiltering && this.parent.filterSettings.type === 'FilterBar' ? len + 1 : len;
        handlerInstance.matrix.matrix = handlerInstance.matrix.matrix.slice(0, defaultLen); //Header matrix update.
        handlerInstance.matrix.rows = defaultLen;
        (_a = handlerInstance.matrix.matrix).push.apply(_a, matrix);
        handlerInstance.matrix.rows += matrix.length;
        var _a;
    };
    ContentFocus.prototype.getInfo = function (e) {
        var info = this.getFocusInfo();
        var _a = this.matrix.current, rIndex = _a[0], cIndex = _a[1];
        var isData = info.element.classList.contains('e-rowcell');
        var isSelectable = isData || (e && e.action !== 'enter' && (info.element.classList.contains('e-detailrowcollapse')
            || info.element.classList.contains('e-detailrowexpand')));
        var _b = [Math.min(parseInt(info.element.parentElement.getAttribute('aria-rowindex'), 10), rIndex),
            Math.min(parseInt(info.element.getAttribute('aria-colindex'), 10), cIndex)], rowIndex = _b[0], cellIndex = _b[1];
        return { isContent: true, isDataCell: isData, indexes: [rowIndex, cellIndex], isSelectable: isSelectable };
    };
    ContentFocus.prototype.validator = function () {
        var table = this.getTable();
        return function (rowIndex, cellIndex, action) {
            var cell = table.rows[rowIndex].cells[cellIndex];
            if (action === 'enter' || action === 'shiftEnter') {
                return cell.classList.contains('e-rowcell');
            }
            if ((action === 'shiftUp' || action === 'shiftDown') && cell.classList.contains('e-rowcell')) {
                return true;
            }
            else if (action !== 'shiftUp' && action !== 'shiftDown') {
                return cell.getBoundingClientRect().width !== 0;
            }
            return false;
        };
    };
    ContentFocus.prototype.shouldFocusChange = function (e) {
        var _a = this.matrix.current, _b = _a[0], rIndex = _b === void 0 ? -1 : _b, _c = _a[1], cIndex = _c === void 0 ? -1 : _c;
        if (rIndex < 0 || cIndex < 0) {
            return true;
        }
        var cell = getValue(rIndex + ".cells." + cIndex, this.getTable().rows);
        if (!cell) {
            return true;
        }
        return e.action === 'enter' || e.action === 'shiftEnter' ?
            cell.classList.contains('e-rowcell') && !cell.classList.contains('e-unboundcell')
                && !cell.classList.contains('e-templatecell') && !cell.classList.contains('e-detailcell') : true;
    };
    return ContentFocus;
}());
export { ContentFocus };
/**
 * @hidden
 */
var HeaderFocus = /** @class */ (function (_super) {
    __extends(HeaderFocus, _super);
    function HeaderFocus(parent) {
        return _super.call(this, parent) || this;
    }
    HeaderFocus.prototype.getTable = function () {
        return (this.parent.frozenColumns ? this.parent.getHeaderContent().querySelector('.e-movableheader .e-table') :
            this.parent.getHeaderTable());
    };
    HeaderFocus.prototype.onClick = function (e) {
        var target = e.target;
        target = (target.classList.contains('e-headercell') ? target : closest(target, 'th'));
        if (!target && this.parent.frozenRows !== 0) {
            target = (e.target.classList.contains('e-rowcell') ? e.target :
                closest(e.target, 'td'));
        }
        if (!target) {
            return;
        }
        var _a = [target.parentElement.rowIndex, target.cellIndex], rowIndex = _a[0], cellIndex = _a[1];
        var val = getValue(rowIndex + "." + cellIndex, this.matrix.matrix);
        if (this.matrix.inValid(val)) {
            return false;
        }
        this.matrix.select(target.parentElement.rowIndex, target.cellIndex);
    };
    HeaderFocus.prototype.getFocusInfo = function () {
        var info = {};
        var _a = this.matrix.current, _b = _a[0], rowIndex = _b === void 0 ? 0 : _b, _c = _a[1], cellIndex = _c === void 0 ? 0 : _c;
        info.element = this.getTable().rows[rowIndex].cells[cellIndex];
        info.elementToFocus = this.getFocusable(info.element);
        info.outline = !info.element.classList.contains('e-filterbarcell');
        return info;
    };
    HeaderFocus.prototype.selector = function (row, cell) {
        return (cell.visible && (cell.column.field !== undefined || cell.isTemplate)) || cell.column.type === 'checkbox' ||
            cell.cellType === CellType.StackedHeader;
    };
    HeaderFocus.prototype.jump = function (action, current) {
        var frozenSwap = this.parent.frozenColumns > 0 &&
            (action === 'leftArrow' || action === 'shiftTab') && current[1] === 0;
        var enterFrozen = this.parent.frozenRows !== 0 && action === 'enter';
        return {
            swap: ((action === 'downArrow' || enterFrozen) && current[0] === this.matrix.matrix.length - 1) ||
                frozenSwap,
            toHeader: frozenSwap,
            toFrozen: frozenSwap
        };
    };
    HeaderFocus.prototype.getNextCurrent = function (previous, swap, active, action) {
        if (previous === void 0) { previous = []; }
        var current = [];
        if (action === 'upArrow' || action === 'shiftEnter') {
            current[0] = this.matrix.matrix.length;
            current[1] = previous[1];
        }
        else if (action === 'rightArrow' || action === 'tab') {
            current[0] = previous[0];
            current[1] = -1;
        }
        return current;
    };
    HeaderFocus.prototype.generateRows = function (rows) {
        var _this = this;
        var length = this.matrix.matrix.length;
        if (this.parent.allowFiltering && this.parent.filterSettings.type === 'FilterBar') {
            this.matrix.rows = ++this.matrix.rows;
            rows[0].cells.forEach(function (cell, cIndex) {
                return _this.matrix.set(length, cIndex, cell.visible && cell.column.allowFiltering !== false);
            });
        }
    };
    HeaderFocus.prototype.getInfo = function (e) {
        return extend(_super.prototype.getInfo.call(this, e), { isContent: false, isHeader: true });
    };
    HeaderFocus.prototype.validator = function () {
        return function () { return true; };
    };
    HeaderFocus.prototype.shouldFocusChange = function (e) {
        var _a = this.matrix.current, rIndex = _a[0], cIndex = _a[1];
        if (rIndex < 0 || cIndex < 0) {
            return true;
        }
        var cell = getValue(rIndex + ".cells." + cIndex, this.getTable().rows);
        if (!cell) {
            return true;
        }
        return e.action === 'enter' || e.action === 'altDownArrow' ? !cell.classList.contains('e-headercell') : true;
    };
    return HeaderFocus;
}(ContentFocus));
export { HeaderFocus };
var FixedContentFocus = /** @class */ (function (_super) {
    __extends(FixedContentFocus, _super);
    function FixedContentFocus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FixedContentFocus.prototype.getTable = function () {
        return this.parent.getContent().querySelector('.e-frozencontent .e-table');
    };
    FixedContentFocus.prototype.jump = function (action, current) {
        var enterFrozen = this.parent.frozenRows !== 0 && action === 'shiftEnter';
        return {
            swap: (action === 'upArrow' || enterFrozen) && current[0] === 0
                || ((action === 'tab' || action === 'rightArrow') && current[1] === this.matrix.columns),
            toHeader: (action === 'upArrow' || enterFrozen) && current[0] === 0,
            toFrozen: (action === 'upArrow' || enterFrozen) && current[0] === 0
        };
    };
    FixedContentFocus.prototype.getNextCurrent = function (previous, swap, active, action) {
        if (previous === void 0) { previous = []; }
        var current = [];
        if (action === 'leftArrow' || action === 'shiftTab') {
            current[0] = previous[0];
            current[1] = active.matrix.columns + 1;
        }
        else if (action === 'downArrow' || action === 'enter') {
            current[0] = -1;
            current[1] = previous[1];
        }
        return current;
    };
    return FixedContentFocus;
}(ContentFocus));
export { FixedContentFocus };
var FixedHeaderFocus = /** @class */ (function (_super) {
    __extends(FixedHeaderFocus, _super);
    function FixedHeaderFocus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FixedHeaderFocus.prototype.jump = function (action, current) {
        var enterFrozen = this.parent.frozenRows !== 0 && action === 'enter';
        return {
            swap: (action === 'downArrow' || enterFrozen) && current[0] === this.matrix.matrix.length - 1
                || ((action === 'rightArrow' || action === 'tab') && current[1] === this.matrix.columns),
            toHeader: (action === 'rightArrow' || action === 'tab') && current[1] === this.matrix.columns,
            toFrozen: (action === 'downArrow' || enterFrozen) && current[0] === this.matrix.matrix.length - 1
        };
    };
    FixedHeaderFocus.prototype.getTable = function () {
        return (this.parent.getHeaderContent().querySelector('.e-frozenheader .e-table'));
    };
    FixedHeaderFocus.prototype.getNextCurrent = function (previous, swap, active, action) {
        if (previous === void 0) { previous = []; }
        var current = [];
        if (action === 'leftArrow' || action === 'shiftTab') {
            current[0] = previous[0];
            current[1] = active.matrix.columns + 1;
        }
        else if (action === 'upArrow' || action === 'shiftEnter') {
            current[0] = this.matrix.matrix.length;
            current[1] = previous[1];
        }
        return current;
    };
    return FixedHeaderFocus;
}(HeaderFocus));
export { FixedHeaderFocus };
/** @hidden */
var SearchBox = /** @class */ (function () {
    function SearchBox(searchBox) {
        this.searchBox = searchBox;
    }
    SearchBox.prototype.searchFocus = function (args) {
        args.target.parentElement.classList.add('e-input-focus');
    };
    SearchBox.prototype.searchBlur = function (args) {
        args.target.parentElement.classList.remove('e-input-focus');
    };
    SearchBox.prototype.wireEvent = function () {
        if (this.searchBox) {
            EventHandler.add(this.searchBox, 'focus', this.searchFocus, this);
            EventHandler.add(this.searchBox, 'blur', this.searchBlur, this);
        }
    };
    SearchBox.prototype.unWireEvent = function () {
        if (this.searchBox) {
            EventHandler.remove(this.searchBox, 'focus', this.searchFocus);
            EventHandler.remove(this.searchBox, 'blur', this.searchBlur);
        }
    };
    return SearchBox;
}());
export { SearchBox };
