var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { createElement, remove } from '@syncfusion/ej2-base';
import { HeaderRender } from './header-renderer';
import { ContentRender } from './content-renderer';
import { FreezeRowModelGenerator } from '../services/freeze-row-model-generator';
import * as events from '../base/constant';
import { renderMovable, getScrollBarWidth } from '../base/util';
/**
 * Freeze module is used to render grid content with frozen rows and columns
 * @hidden
 */
var FreezeContentRender = /** @class */ (function (_super) {
    __extends(FreezeContentRender, _super);
    function FreezeContentRender(parent, locator) {
        return _super.call(this, parent, locator) || this;
    }
    FreezeContentRender.prototype.renderPanel = function () {
        _super.prototype.renderPanel.call(this);
        var fDiv = createElement('div', { className: 'e-frozencontent' });
        var mDiv = createElement('div', { className: 'e-movablecontent' });
        this.getPanel().firstChild.appendChild(fDiv);
        this.getPanel().firstChild.appendChild(mDiv);
        this.setFrozenContent(fDiv);
        this.setMovableContent(mDiv);
    };
    FreezeContentRender.prototype.renderEmpty = function (tbody) {
        _super.prototype.renderEmpty.call(this, tbody);
        this.getMovableContent().querySelector('tbody').innerHTML = '<tr><td></td></tr>';
        this.getFrozenContent().querySelector('.e-emptyrow').querySelector('td').colSpan = this.parent.getFrozenColumns();
        this.getFrozenContent().style.borderRightWidth = '0px';
        if (this.parent.frozenRows) {
            this.parent.getHeaderContent().querySelector('.e-frozenheader').querySelector('tbody').innerHTML = '';
            this.parent.getHeaderContent().querySelector('.e-movableheader').querySelector('tbody').innerHTML = '';
        }
    };
    FreezeContentRender.prototype.setFrozenContent = function (ele) {
        this.frozenContent = ele;
    };
    FreezeContentRender.prototype.setMovableContent = function (ele) {
        this.movableContent = ele;
    };
    FreezeContentRender.prototype.getFrozenContent = function () {
        return this.frozenContent;
    };
    FreezeContentRender.prototype.getMovableContent = function () {
        return this.movableContent;
    };
    FreezeContentRender.prototype.getModelGenerator = function () {
        return new FreezeRowModelGenerator(this.parent);
    };
    FreezeContentRender.prototype.renderTable = function () {
        _super.prototype.renderTable.call(this);
        this.getFrozenContent().appendChild(this.getTable());
        var mTbl = this.getTable().cloneNode(true);
        this.getMovableContent().appendChild(mTbl);
        remove(this.getMovableContent().querySelector('colgroup'));
        var colGroup = ((this.parent.getHeaderContent().querySelector('.e-movableheader').querySelector('colgroup')).cloneNode(true));
        mTbl.insertBefore(colGroup, mTbl.querySelector('tbody'));
    };
    return FreezeContentRender;
}(ContentRender));
export { FreezeContentRender };
var FreezeRender = /** @class */ (function (_super) {
    __extends(FreezeRender, _super);
    function FreezeRender(parent, locator) {
        var _this = _super.call(this, parent, locator) || this;
        _this.addEventListener();
        return _this;
    }
    FreezeRender.prototype.addEventListener = function () {
        this.parent.on(events.freezeRender, this.refreshFreeze, this);
    };
    FreezeRender.prototype.renderTable = function () {
        _super.prototype.renderTable.call(this);
        this.rfshMovable();
        this.updateColgroup();
        this.initializeHeaderDrag();
        this.initializeHeaderDrop();
        this.parent.notify(events.headerRefreshed, { rows: this.rows, args: { isFrozen: false } });
    };
    FreezeRender.prototype.renderPanel = function () {
        _super.prototype.renderPanel.call(this);
        var fDiv = createElement('div', { className: 'e-frozenheader' });
        var mDiv = createElement('div', { className: 'e-movableheader' });
        this.getPanel().firstChild.appendChild(fDiv);
        this.getPanel().firstChild.appendChild(mDiv);
        this.setFrozenHeader(fDiv);
        this.setMovableHeader(mDiv);
    };
    FreezeRender.prototype.refreshUI = function () {
        var tbody = this.getMovableHeader().querySelector('tbody');
        remove(this.getMovableHeader().querySelector('table'));
        _super.prototype.refreshUI.call(this);
        this.rfshMovable();
        this.getMovableHeader().querySelector('tbody').innerHTML = tbody.innerHTML;
        this.updateColgroup();
        renderMovable(this.parent.getContentTable().querySelector('colgroup'), this.parent.getFrozenColumns());
        this.initializeHeaderDrag();
        this.parent.notify(events.headerRefreshed, { rows: this.rows, args: { isFrozen: false } });
    };
    FreezeRender.prototype.rfshMovable = function () {
        this.getFrozenHeader().appendChild(this.getTable());
        this.getMovableHeader().appendChild(this.createTable());
        this.refreshStackedHdrHgt();
    };
    FreezeRender.prototype.refreshFreeze = function (obj) {
        if (obj.case === 'filter') {
            var filterRow = this.getTable().querySelector('.e-filterbar');
            if (this.parent.allowFiltering && filterRow && this.getMovableHeader().querySelector('thead')) {
                this.getMovableHeader().querySelector('thead')
                    .appendChild(renderMovable(filterRow, this.parent.getFrozenColumns()));
            }
        }
        else if (obj.case === 'textwrap') {
            var fRows = void 0;
            var mRows = void 0;
            var fHdr = this.getFrozenHeader();
            var mHdr = this.getMovableHeader();
            var cont = this.parent.getContent();
            var wrapMode = this.parent.textWrapSettings.wrapMode;
            var hdrClassList = this.parent.getHeaderContent().firstChild.classList;
            if (wrapMode !== 'Header' || obj.isModeChg) {
                fRows = cont.querySelector('.e-frozencontent').querySelectorAll('tr');
                mRows = cont.querySelector('.e-movablecontent').querySelectorAll('tr');
                this.setWrapHeight(fRows, mRows, obj.isModeChg, true);
            }
            if (wrapMode === 'Content' && this.parent.allowTextWrap) {
                hdrClassList.add('e-wrap');
            }
            else {
                hdrClassList.remove('e-wrap');
            }
            if (wrapMode === 'Both' || obj.isModeChg) {
                fRows = fHdr.querySelectorAll('tr');
                mRows = mHdr.querySelectorAll('tr');
            }
            else {
                fRows = fHdr.querySelector(wrapMode === 'Content' ? 'tbody' : 'thead').querySelectorAll('tr');
                mRows = mHdr.querySelector(wrapMode === 'Content' ? 'tbody' : 'thead').querySelectorAll('tr');
            }
            this.setWrapHeight(fRows, mRows, obj.isModeChg, false, this.colDepth > 1);
            this.refreshStackedHdrHgt();
        }
    };
    FreezeRender.prototype.updateResizeHandler = function () {
        [].slice.call(this.parent.getHeaderContent().querySelectorAll('.e-rhandler')).forEach(function (ele) {
            ele.style.height = ele.parentElement.offsetHeight + 'px';
        });
    };
    FreezeRender.prototype.setWrapHeight = function (fRows, mRows, isModeChg, isContReset, isStackedHdr) {
        var fRowHgt;
        var mRowHgt;
        var isWrap = this.parent.allowTextWrap;
        var wrapMode = this.parent.textWrapSettings.wrapMode;
        var tHead = this.parent.getHeaderContent().querySelector('thead');
        var tBody = this.parent.getHeaderContent().querySelector('tbody');
        var height = [];
        var width = [];
        for (var i = 0, len = fRows.length; i < len; i++) {
            height[i] = fRows[i].offsetHeight; //https://pagebuildersandwich.com/increased-plugins-performance-200/
            width[i] = mRows[i].offsetHeight;
        }
        for (var i = 0, len = fRows.length; i < len; i++) {
            if (isModeChg && ((wrapMode === 'Header' && isContReset) || ((wrapMode === 'Content' && tHead.contains(fRows[i]))
                || (wrapMode === 'Header' && tBody.contains(fRows[i])))) || isStackedHdr) {
                fRows[i].style.height = null;
                mRows[i].style.height = null;
            }
            fRowHgt = height[i];
            mRowHgt = width[i];
            if (fRows[i].childElementCount && ((isWrap && fRowHgt < mRowHgt) || (!isWrap && fRowHgt > mRowHgt))) {
                fRows[i].style.height = mRowHgt + 'px';
            }
            else if (mRows[i].childElementCount && ((isWrap && fRowHgt > mRowHgt) || (!isWrap && fRowHgt < mRowHgt))) {
                mRows[i].style.height = fRowHgt + 'px';
            }
        }
        if (isWrap) {
            var movableContentHeight = this.parent.element.querySelector('.e-movablecontent').getBoundingClientRect().height;
            var frozenContentHeight = this.parent.element.querySelector('.e-frozencontent').getBoundingClientRect().height;
            if (movableContentHeight > frozenContentHeight) {
                this.parent.element.querySelector('.e-frozencontent').style.height = movableContentHeight -
                    getScrollBarWidth() + 'px';
            }
        }
    };
    FreezeRender.prototype.refreshStackedHdrHgt = function () {
        var fRowSpan;
        var mRowSpan;
        var fTr = this.getFrozenHeader().querySelectorAll('.e-columnheader');
        var mTr = this.getMovableHeader().querySelectorAll('.e-columnheader');
        for (var i = 0, len = fTr.length; i < len; i++) {
            fRowSpan = this.getRowSpan(fTr[i]);
            mRowSpan = this.getRowSpan(mTr[i]);
            if (fRowSpan.min > 1) {
                this.updateStackedHdrRowHgt(i, fRowSpan.max, fTr[i], mTr);
            }
            else if (mRowSpan.min > 1) {
                this.updateStackedHdrRowHgt(i, mRowSpan.max, mTr[i], fTr);
            }
        }
        if (this.parent.allowResizing) {
            this.updateResizeHandler();
        }
    };
    FreezeRender.prototype.getRowSpan = function (row) {
        var rSpan;
        var minRowSpan;
        var maxRowSpan;
        for (var i = 0, len = row.childElementCount; i < len; i++) {
            if (i === 0) {
                minRowSpan = row.children[0].rowSpan;
            }
            rSpan = row.children[i].rowSpan;
            minRowSpan = Math.min(rSpan, minRowSpan);
            maxRowSpan = Math.max(rSpan, minRowSpan);
        }
        return { min: minRowSpan, max: maxRowSpan };
    };
    FreezeRender.prototype.updateStackedHdrRowHgt = function (idx, maxRowSpan, row, rows) {
        var height = 0;
        for (var i = 0; i < maxRowSpan; i++) {
            height += rows[idx + i].offsetHeight;
        }
        row.style.height = height + 'px';
    };
    FreezeRender.prototype.setFrozenHeader = function (ele) {
        this.frozenHeader = ele;
    };
    FreezeRender.prototype.setMovableHeader = function (ele) {
        this.movableHeader = ele;
    };
    FreezeRender.prototype.getFrozenHeader = function () {
        return this.frozenHeader;
    };
    FreezeRender.prototype.getMovableHeader = function () {
        return this.movableHeader;
    };
    FreezeRender.prototype.updateColgroup = function () {
        var mTable = this.getMovableHeader().querySelector('table');
        remove(this.getMovableHeader().querySelector('colgroup'));
        mTable.insertBefore(renderMovable(this.getFrozenHeader().querySelector('colgroup'), this.parent.getFrozenColumns()), mTable.querySelector('thead'));
    };
    return FreezeRender;
}(HeaderRender));
export { FreezeRender };
