import { print as printWindow, createElement, detach } from '@syncfusion/ej2-base';
import { removeElement, getActualProperties, getActualPropFromColl } from '../base/util';
import { Grid } from '../base/grid';
import * as events from '../base/constant';
/**
 *
 * The `Print` module is used to handle print action.
 */
var Print = /** @class */ (function () {
    /**
     * Constructor for the Grid print module
     * @hidden
     */
    function Print(parent, scrollModule) {
        this.isAsyncPrint = false;
        this.printing = 'isPrinting';
        this.parent = parent;
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.on(events.contentReady, this.contentReady.bind(this));
        this.parent.addEventListener(events.actionBegin, this.actionBegin.bind(this));
        this.parent.on(events.onEmpty, this.onEmpty.bind(this));
        this.scrollModule = scrollModule;
    }
    /**
     * By default, prints all the Grid pages and hides the pager.
     * > You can customize print options using the
     * [`printMode`](./api-grid.html#printmode-string).
     * @return {void}
     */
    Print.prototype.print = function () {
        this.renderPrintGrid();
        this.printWind = window.open('', 'print', 'height=' + window.outerHeight + ',width=' + window.outerWidth + ',tabbar=no');
        this.printWind.moveTo(0, 0);
        this.printWind.resizeTo(screen.availWidth, screen.availHeight);
    };
    Print.prototype.onEmpty = function () {
        if (this.isPrintGrid()) {
            this.contentReady();
        }
    };
    Print.prototype.actionBegin = function () {
        if (this.isPrintGrid()) {
            this.isAsyncPrint = true;
        }
    };
    Print.prototype.renderPrintGrid = function () {
        var gObj = this.parent;
        var elem = 'element';
        var printGridModel = {};
        var element = createElement('div', {
            id: this.parent.element.id + '_print', className: gObj.element.className + ' e-print-grid'
        });
        document.body.appendChild(element);
        for (var _i = 0, _a = Print.printGridProp; _i < _a.length; _i++) {
            var key = _a[_i];
            if (key === 'columns') {
                printGridModel[key] = getActualPropFromColl(gObj[key]);
            }
            else if (key === 'allowPaging') {
                printGridModel[key] = this.parent.printMode === 'CurrentPage';
            }
            else {
                printGridModel[key] = getActualProperties(gObj[key]);
            }
        }
        var printGrid = new Grid(printGridModel);
        printGrid.appendTo(element);
        printGrid[this.printing] = true;
        printGrid.trigger = gObj.trigger;
    };
    Print.prototype.contentReady = function () {
        if (this.isPrintGrid()) {
            var gObj = this.parent;
            if (this.isAsyncPrint) {
                this.printGrid();
                return;
            }
            var args = {
                requestType: 'print',
                element: gObj.element,
                selectedRows: gObj.getContentTable().querySelectorAll('tr[aria-selected="true"]'),
                cancel: false
            };
            if (!this.isAsyncPrint) {
                gObj.trigger(events.beforePrint, args);
            }
            if (args.cancel) {
                detach(gObj.element);
                return;
            }
            else if (!this.isAsyncPrint) {
                this.printGrid();
            }
        }
    };
    Print.prototype.printGrid = function () {
        var gObj = this.parent;
        // Pager eleement process based on primt mode
        if (gObj.allowPaging && gObj.printMode === 'CurrentPage') {
            gObj.element.querySelector('.e-gridpager').style.display = 'none';
        }
        // Height adjustment on print grid
        if (gObj.height !== 'auto') {
            var cssProps = this.scrollModule.getCssProperties();
            var contentDiv = gObj.element.querySelector('.e-content');
            var headerDiv = gObj.element.querySelector('.e-gridheader');
            contentDiv.style.height = 'auto';
            contentDiv.style.overflowY = 'auto';
            headerDiv.style[cssProps.padding] = '';
            headerDiv.firstElementChild.style[cssProps.border] = '';
        }
        // Grid alignment adjustment on grouping
        if (gObj.allowGrouping) {
            if (!gObj.groupSettings.columns.length) {
                gObj.element.querySelector('.e-groupdroparea').style.display = 'none';
            }
            else {
                this.removeColGroup(gObj.groupSettings.columns.length, gObj.element);
                removeElement(gObj.element, '.e-grouptopleftcell');
                removeElement(gObj.element, '.e-recordpluscollapse');
                removeElement(gObj.element, '.e-indentcell');
                removeElement(gObj.element, '.e-recordplusexpand');
            }
        }
        // hide horizontal scroll
        gObj.element.querySelector('.e-content').style.overflowX = 'hidden';
        //hide filter bar in print grid
        if (gObj.allowFiltering && gObj.filterSettings.type === 'FilterBar') {
            gObj.element.querySelector('.e-filterbar').style.display = 'none';
        }
        // Hide the waiting popup
        var waitingPop = gObj.element.querySelectorAll('.e-spin-show');
        if (waitingPop.length > 0) {
            waitingPop[0].classList.add('e-spin-hide');
            waitingPop[0].classList.remove('e-spin-show');
        }
        if (gObj[this.printing]) {
            detach(gObj.element);
        }
        gObj.element.classList.remove('e-print-grid');
        this.printWind = printWindow(gObj.element, this.printWind);
        gObj[this.printing] = false;
        var args = {
            element: gObj.element
        };
        gObj.trigger(events.printComplete, args);
    };
    Print.prototype.removeColGroup = function (depth, element) {
        var groupCaption = element.querySelectorAll('.e-groupcaption');
        var colSpan = groupCaption[depth - 1].getAttribute('colspan');
        for (var i = 0; i < groupCaption.length; i++) {
            groupCaption[i].setAttribute('colspan', colSpan);
        }
        var colGroups = element.querySelectorAll('colgroup');
        for (var i = 0; i < colGroups.length; i++) {
            for (var j = 0; j < depth; j++) {
                colGroups[i].childNodes[j].style.display = 'none';
            }
        }
    };
    Print.prototype.isPrintGrid = function () {
        return this.parent.element.id.indexOf('_print') > 0 && this.parent[this.printing];
    };
    /**
     * To destroy the print
     * @return {void}
     * @hidden
     */
    Print.prototype.destroy = function () {
        if (this.parent.isDestroyed) {
            return;
        }
        this.parent.off(events.contentReady, this.contentReady.bind(this));
        this.parent.removeEventListener(events.actionBegin, this.actionBegin.bind(this));
        this.parent.off(events.onEmpty, this.onEmpty.bind(this));
    };
    /**
     * For internal use only - Get the module name.
     * @private
     */
    Print.prototype.getModuleName = function () {
        return 'print';
    };
    Print.printGridProp = [
        'aggregates', 'allowGrouping', 'allowFiltering', 'allowMultiSorting', 'allowReordering', 'allowSorting',
        'allowTextWrap', 'childGrid', 'columns', 'currentViewData', 'dataSource', 'detailTemplate', 'enableAltRow',
        'enableColumnVirtualization', 'filterSettings', 'gridLines',
        'groupSettings', 'height', 'locale', 'pageSettings', 'printMode', 'query', 'queryString',
        'rowHeight', 'rowTemplate', 'sortSettings', 'textWrapSettings', 'allowPaging',
        events.beforePrint, events.printComplete
    ];
    return Print;
}());
export { Print };
