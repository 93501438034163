import { extend } from '@syncfusion/ej2-base';
import { createElement, isNullOrUndefined } from '@syncfusion/ej2-base';
import { DatePicker } from '@syncfusion/ej2-calendars';
import { isEditable, isComplexField, getComplexFieldID, getComplexValue } from '../base/util';
/**
 * `DatePickerEditCell` is used to handle datepicker cell type editing.
 * @hidden
 */
var DatePickerEditCell = /** @class */ (function () {
    function DatePickerEditCell(parent) {
        this.parent = parent;
    }
    DatePickerEditCell.prototype.create = function (args) {
        /* tslint:disable-next-line:no-any */
        var complexFieldName = getComplexFieldID(args.column.field);
        var isComplex = isComplexField(args.column.field);
        return createElement('input', {
            className: 'e-field', attrs: {
                id: isComplex ? this.parent.element.id + complexFieldName : this.parent.element.id + args.column.field,
                name: isComplex ? complexFieldName : args.column.field, type: 'text', 'e-mappinguid': args.column.uid
            }
        });
    };
    DatePickerEditCell.prototype.read = function (element) {
        return element.ej2_instances[0].value;
    };
    DatePickerEditCell.prototype.write = function (args) {
        var isInline = this.parent.editSettings.mode !== 'Dialog';
        /* tslint:disable-next-line:no-any */
        var isComplex = !isNullOrUndefined(args.column.field) && isComplexField(args.column.field);
        var isAddRow = args.requestType === 'add' || args.row.classList.contains('e-addedrow');
        this.obj = new DatePicker(extend({
            floatLabelType: isInline ? 'Never' : 'Always',
            value: isComplex && !isAddRow ?
                new Date(getComplexValue(args.rowData, args.column.field)) : new Date(args.rowData[args.column.field]),
            placeholder: isInline ?
                '' : args.column.headerText, enableRtl: this.parent.enableRtl,
            enabled: isEditable(args.column, args.type, args.element),
        }, args.column.edit.params));
        this.obj.appendTo(args.element);
    };
    DatePickerEditCell.prototype.destroy = function () {
        if (this.obj) {
            this.obj.destroy();
        }
    };
    return DatePickerEditCell;
}());
export { DatePickerEditCell };
