import { merge, isNullOrUndefined, getValue } from '@syncfusion/ej2-base';
import { Query, DataUtil } from '@syncfusion/ej2-data';
import { ValueFormatter } from '../services/value-formatter';
import { getUid, templateCompiler, getForeignData } from '../base/util';
/**
 * Represents Grid `Column` model class.
 */
var Column = /** @class */ (function () {
    function Column(options) {
        var _this = this;
        /**
         * If `allowSorting` set to false, then it disables sorting option of a particular column.
         * By default all columns are sortable.
         * @default true
         */
        this.allowSorting = true;
        /**
         * If `allowResizing` is set to false, it disables resize option of a particular column.
         * By default all the columns can be resized.
         * @default true
         */
        this.allowResizing = true;
        /**
         * If `allowFiltering` set to false, then it disables filtering option and filter bar element of a particular column.
         * By default all columns are filterable.
         * @default true
         */
        this.allowFiltering = true;
        /**
         * If `allowGrouping` set to false, then it disables grouping of a particular column.
         * By default all columns are groupable.
         * @default true
         */
        this.allowGrouping = true;
        /**
         * If `showColumnMenu` set to false, then it disable the column menu of a particular column.
         * By default column menu will show for all columns
         * @default true
         */
        this.showColumnMenu = true;
        /**
         * If `enableGroupByFormat` set to true, then it groups the particular column by formatted values.
         * @default true
         */
        this.enableGroupByFormat = false;
        /**
         * If `allowEditing` set to false, then it disables editing of a particular column.
         * By default all columns are editable.
         * @default true
         */
        this.allowEditing = true;
        /**
         *  It is used to customize the default filter options for a specific columns.
         * * type -  Specifies the filter type as menu or checkbox.
         * * ui - to render custom component for specific column it has following functions.
         * * ui.create – It is used for creating custom components.
         * * ui.read -  It is used for read the value from the component.
         * * ui.write - It is used to apply component model as dynamically.
         * {% codeBlock src="grid/filter-menu-api/index.ts" %}{% endcodeBlock %}
         *
         * > Check the [`Filter UI`](./filtering.html#custom-component-in-filter-menu) for its customization.
         *  @default null
         */
        this.filter = {};
        /**
         * If `showInColumnChooser` set to false, then hide the particular column in column chooser.
         *  By default all columns are displayed in column Chooser.
         * @default true
         */
        this.showInColumnChooser = true;
        /**
         * Defines the `IEditCell` object to customize default edit cell.
         * @default {}
         */
        this.edit = {};
        this.sortDirection = 'Descending';
        merge(this, options);
        this.uid = getUid('grid-column');
        var valueFormatter = new ValueFormatter();
        if (options.format && (options.format.skeleton || options.format.format)) {
            this.setFormatter(valueFormatter.getFormatFunction(options.format));
            this.setParser(valueFormatter.getParserFunction(options.format));
        }
        if (!this.field) {
            this.allowFiltering = false;
            this.allowGrouping = false;
            this.allowSorting = false;
        }
        if (this.commands && !this.textAlign) {
            this.textAlign = 'Right';
        }
        if (this.template || this.commandsTemplate) {
            this.templateFn = templateCompiler(this.template || this.commandsTemplate);
        }
        if (this.headerTemplate) {
            this.headerTemplateFn = templateCompiler(this.headerTemplate);
        }
        if (this.filter.itemTemplate) {
            this.fltrTemplateFn = templateCompiler(this.filter.itemTemplate);
        }
        if (this.isForeignColumn() && (isNullOrUndefined(this.editType) || this.editType === 'dropdownedit')) {
            this.editType = 'dropdownedit';
            this.edit.params = {
                dataSource: this.dataSource,
                query: new Query(), fields: { value: this.foreignKeyField || this.field, text: this.foreignKeyValue }
            };
        }
        if (this.sortComparer) {
            var a_1 = this.sortComparer;
            this.sortComparer = function comparer(x, y) {
                if (typeof a_1 === 'string') {
                    a_1 = getValue(a_1, window);
                }
                if (this.sortDirection === 'Descending') {
                    var z = x;
                    x = y;
                    y = z;
                }
                return a_1(x, y);
            };
        }
        if (!this.sortComparer && this.isForeignColumn()) {
            this.sortComparer = function (x, y) {
                x = getValue(_this.foreignKeyValue, getForeignData(_this, {}, x)[0]);
                y = getValue(_this.foreignKeyValue, getForeignData(_this, {}, y)[0]);
                return _this.sortDirection === 'Descending' ? DataUtil.fnDescending(x, y) : DataUtil.fnAscending(x, y);
            };
        }
    }
    /** @hidden */
    Column.prototype.getSortDirection = function () {
        return this.sortDirection;
    };
    /** @hidden */
    Column.prototype.setSortDirection = function (direction) {
        this.sortDirection = direction;
    };
    /** @hidden */
    Column.prototype.setProperties = function (column) {
        //Angular two way binding
        var keys = Object.keys(column);
        for (var i = 0; i < keys.length; i++) {
            this[keys[i]] = column[keys[i]];
        }
    };
    /**
     * @hidden
     * It defines the column is foreign key column or not.
     */
    Column.prototype.isForeignColumn = function () {
        return !!(this.dataSource && this.foreignKeyValue);
    };
    /** @hidden */
    Column.prototype.getFormatter = function () {
        return this.formatFn;
    };
    /** @hidden */
    Column.prototype.setFormatter = function (value) {
        this.formatFn = value;
    };
    /** @hidden */
    Column.prototype.getParser = function () {
        return this.parserFn;
    };
    /** @hidden */
    Column.prototype.setParser = function (value) {
        this.parserFn = value;
    };
    /** @hidden */
    Column.prototype.getColumnTemplate = function () {
        return this.templateFn;
    };
    /** @hidden */
    Column.prototype.getHeaderTemplate = function () {
        return this.headerTemplateFn;
    };
    /** @hidden */
    Column.prototype.getFilterItemTemplate = function () {
        return this.fltrTemplateFn;
    };
    /** @hidden */
    Column.prototype.getDomSetter = function () {
        return this.disableHtmlEncode ? 'textContent' : 'innerHTML';
    };
    return Column;
}());
export { Column };
