import { Row } from './../models/row';
import { CellType } from '../base/enum';
import { isNullOrUndefined, Internationalization, getValue } from '@syncfusion/ej2-base';
import { Cell } from '../models/cell';
import { ValueFormatter } from './../services/value-formatter';
import { Query } from '@syncfusion/ej2-data';
import { getForeignData } from '../base/util';
/**
 * @hidden
 * `ExportHelper` for `PdfExport` & `ExcelExport`
 */
var ExportHelper = /** @class */ (function () {
    function ExportHelper(parent) {
        this.hideColumnInclude = false;
        this.foreignKeyData = {};
        this.parent = parent;
    }
    ExportHelper.getQuery = function (parent, data) {
        return data.isRemote() ?
            data.generateQuery(true).requiresCount().take(parent.pageSettings.totalRecordsCount) :
            data.generateQuery(true).requiresCount();
    };
    ExportHelper.prototype.getFData = function (value, column) {
        var foreignKeyData = getForeignData(column, {}, value, this.foreignKeyData[column.field])[0];
        return foreignKeyData;
    };
    ExportHelper.prototype.getColumnData = function (gridObj) {
        var _this = this;
        var columnPromise = [];
        var promise;
        var fColumns = gridObj.getForeignKeyColumns();
        if (fColumns.length) {
            fColumns.forEach(function (col) {
                columnPromise.push(col.dataSource.executeQuery(new Query()));
            });
            promise = Promise.all(columnPromise).then(function (e) {
                fColumns.forEach(function (col, index) {
                    _this.foreignKeyData[col.field] = e[index].result;
                });
                // tslint:disable-next-line:no-any
            });
        }
        return promise;
    };
    /* tslint:disable:no-any */
    ExportHelper.prototype.getHeaders = function (column, isHideColumnInclude) {
        if (isHideColumnInclude) {
            this.hideColumnInclude = true;
        }
        else {
            this.hideColumnInclude = false;
        }
        var cols = column;
        this.colDepth = this.measureColumnDepth(cols);
        var rows = [];
        var actualColumns = [];
        for (var i = 0; i < this.colDepth; i++) {
            rows[i] = new Row({});
            rows[i].cells = [];
        }
        rows = this.processColumns(rows);
        rows = this.processHeaderCells(rows);
        for (var _i = 0, rows_1 = rows; _i < rows_1.length; _i++) {
            var row = rows_1[_i];
            for (var i = 0; i < row.cells.length; i++) {
                var cell = row.cells[i];
                if (cell.visible === undefined && cell.cellType !== CellType.StackedHeader) {
                    row.cells = this.removeCellFromRow(row.cells, i);
                    i = i - 1;
                }
                if ((!isHideColumnInclude) && cell.visible !== undefined && (!cell.visible)) {
                    row.cells = this.removeCellFromRow(row.cells, i);
                    i = i - 1;
                }
            }
        }
        for (var i = 0; i < cols.length; i++) {
            this.generateActualColumns(cols[i], actualColumns);
        }
        return { rows: rows, columns: actualColumns };
    };
    ExportHelper.prototype.getConvertedWidth = function (input) {
        var value = parseFloat(input);
        /* tslint:disable-next-line:max-line-length */
        return (input.indexOf('%') !== -1) ? (this.parent.element.getBoundingClientRect().width * value / 100) : value;
    };
    ExportHelper.prototype.generateActualColumns = function (column, actualColumns) {
        if (column.commands) {
            return;
        }
        if (!column.columns) {
            if (column.visible || this.hideColumnInclude) {
                actualColumns.push(column);
            }
        }
        else {
            if (column.visible || this.hideColumnInclude) {
                var colSpan = this.getCellCount(column, 0);
                if (colSpan !== 0) {
                    for (var i = 0; i < column.columns.length; i++) {
                        /* tslint:disable-next-line:max-line-length */
                        this.generateActualColumns(column.columns[i], actualColumns);
                    }
                }
            }
        }
    };
    ExportHelper.prototype.removeCellFromRow = function (cells, cellIndex) {
        var resultCells = [];
        for (var i = 0; i < cellIndex; i++) {
            resultCells.push(cells[i]);
        }
        for (var i = (cellIndex + 1); i < cells.length; i++) {
            resultCells.push(cells[i]);
        }
        return resultCells;
    };
    ExportHelper.prototype.processHeaderCells = function (rows) {
        var columns = this.parent.enableColumnVirtualization ? this.parent.getColumns() : this.parent.columns;
        for (var i = 0; i < columns.length; i++) {
            if (!columns[i].commands) {
                rows = this.appendGridCells(columns[i], rows, 0, i === 0, false, i === (columns.length - 1));
            }
        }
        return rows;
    };
    /* tslint:disable */
    ExportHelper.prototype.appendGridCells = function (cols, gridRows, index, isFirstObj, isFirstColumn, isLastColumn) {
        /* tslint:enable */
        var lastCol = isLastColumn ? 'e-lastcell' : '';
        if (!cols.columns) {
            gridRows[index].cells.push(this.generateCell(cols, CellType.Header, this.colDepth - index, (isFirstObj ? '' : (isFirstColumn ? 'e-firstcell' : '')) + lastCol, index, this.parent.getColumnIndexByUid(cols.uid)));
        }
        else {
            var colSpan = this.getCellCount(cols, 0);
            if (colSpan) {
                gridRows[index].cells.push(new Cell({
                    cellType: CellType.StackedHeader, column: cols, colSpan: colSpan
                }));
            }
            var isFirstCell = void 0;
            var isIgnoreFirstCell = void 0;
            for (var i = 0, len = cols.columns.length; i < len; i++) {
                isFirstCell = false;
                if (cols.columns[i].visible && !isIgnoreFirstCell) {
                    isFirstCell = true;
                    isIgnoreFirstCell = true;
                }
                /* tslint:disable-next-line:max-line-length */
                gridRows = this.appendGridCells(cols.columns[i], gridRows, index + 1, isFirstObj, i === 0, i === (len - 1) && isLastColumn);
            }
        }
        return gridRows;
    };
    ExportHelper.prototype.generateCell = function (gridColumn, cellType, rowSpan, className, rowIndex, columnIndex) {
        var option = {
            'visible': gridColumn.visible,
            'isDataCell': false,
            'isTemplate': !isNullOrUndefined(gridColumn.headerTemplate),
            'rowID': '',
            'column': gridColumn,
            'cellType': cellType,
            'rowSpan': rowSpan,
            'className': className,
            'index': rowIndex,
            'colIndex': columnIndex
        };
        if (!option.rowSpan || option.rowSpan < 2) {
            delete option.rowSpan;
        }
        return new Cell(option);
    };
    ExportHelper.prototype.processColumns = function (rows) {
        //TODO: generate dummy column for group, detail, stacked row here; ensureColumns here
        var gridObj = this.parent;
        var columnIndexes = this.parent.getColumnIndexesInView();
        for (var i = 0, len = rows.length; i < len; i++) {
            if (gridObj.allowGrouping) {
                for (var j = 0, len_1 = gridObj.groupSettings.columns.length; j < len_1; j++) {
                    if (this.parent.enableColumnVirtualization && columnIndexes.indexOf(j) === -1) {
                        continue;
                    }
                    rows[i].cells.push(this.generateCell({}, CellType.HeaderIndent));
                }
            }
            if (gridObj.detailTemplate || gridObj.childGrid) {
                rows[i].cells.push(this.generateCell({}, CellType.DetailHeader));
            }
        }
        return rows;
    };
    /* tslint:disable:no-any */
    ExportHelper.prototype.getCellCount = function (column, count) {
        if (column.columns) {
            for (var i = 0; i < column.columns.length; i++) {
                count = this.getCellCount(column.columns[i], count);
            }
        }
        else {
            if (column.visible || this.hideColumnInclude) {
                count++;
            }
        }
        return count;
    };
    /* tslint:disable:no-any */
    ExportHelper.prototype.measureColumnDepth = function (column) {
        var max = 0;
        for (var i = 0; i < column.length; i++) {
            var depth = this.checkDepth(column[i], 0);
            if (max < depth) {
                max = depth;
            }
        }
        return max + 1;
    };
    /* tslint:disable:no-any */
    ExportHelper.prototype.checkDepth = function (col, index) {
        if (col.columns) {
            index++;
            for (var i = 0; i < col.columns.length; i++) {
                index = this.checkDepth(col.columns[i], index);
            }
        }
        return index;
    };
    ;
    return ExportHelper;
}());
export { ExportHelper };
/**
 * @hidden
 * `ExportValueFormatter` for `PdfExport` & `ExcelExport`
 */
var ExportValueFormatter = /** @class */ (function () {
    function ExportValueFormatter(culture) {
        this.valueFormatter = new ValueFormatter(culture);
        this.internationalization = new Internationalization(culture);
    }
    /* tslint:disable-next-line:no-any */
    ExportValueFormatter.prototype.returnFormattedValue = function (args, customFormat) {
        if (!isNullOrUndefined(args.value) && args.value) {
            return this.valueFormatter.getFormatFunction(customFormat)(args.value);
        }
        else {
            return '';
        }
    };
    /* tslint:disable-next-line:no-any */
    ExportValueFormatter.prototype.formatCellValue = function (args) {
        if (args.isForeignKey) {
            args.value = getValue(args.column.foreignKeyValue, getForeignData(args.column, {}, args.value)[0]);
        }
        if (args.column.type === 'number' && args.column.format !== undefined && args.column.format !== '') {
            return args.value ? this.internationalization.getNumberFormat({ format: args.column.format })(args.value) : '';
        }
        else if (args.column.type === 'boolean') {
            return args.value ? 'true' : 'false';
            /* tslint:disable-next-line:max-line-length */
        }
        else if ((args.column.type === 'date' || args.column.type === 'datetime' || args.column.type === 'time') && args.column.format !== undefined) {
            if (typeof args.column.format === 'string') {
                var format = void 0;
                if (args.column.type === 'date') {
                    format = { type: 'date', skeleton: args.column.format };
                }
                else if (args.column.type === 'time') {
                    format = { type: 'time', skeleton: args.column.format };
                }
                else {
                    format = { type: 'dateTime', skeleton: args.column.format };
                }
                return this.returnFormattedValue(args, format);
            }
            else {
                if (args.column.format instanceof Object && args.column.format.type === undefined) {
                    return (args.value.toString());
                }
                else {
                    /* tslint:disable-next-line:max-line-length */
                    var customFormat = void 0;
                    if (args.column.type === 'date') {
                        /* tslint:disable-next-line:max-line-length */
                        customFormat = { type: args.column.format.type, format: args.column.format.format, skeleton: args.column.format.skeleton };
                    }
                    else if (args.column.type === 'time') {
                        customFormat = { type: 'time', format: args.column.format.format, skeleton: args.column.format.skeleton };
                    }
                    else {
                        customFormat = { type: 'dateTime', format: args.column.format.format, skeleton: args.column.format.skeleton };
                    }
                    return this.returnFormattedValue(args, customFormat);
                }
            }
        }
        else {
            if (args.column.type === undefined || args.column.type === null) {
                return '';
            }
            else {
                return (args.value).toString();
            }
        }
    };
    return ExportValueFormatter;
}());
export { ExportValueFormatter };
