var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { createElement, isNullOrUndefined, remove } from '@syncfusion/ej2-base';
import { formatUnit } from '@syncfusion/ej2-base';
import { Browser } from '@syncfusion/ej2-base';
import { colGroupRefresh, columnWidthChanged, scroll, columnVisibilityChanged } from '../base/constant';
import { ContentRender } from './content-renderer';
import { RowRenderer } from './row-renderer';
import { SummaryModelGenerator } from '../services/summary-model-generator';
import { renderMovable } from '../base/util';
/**
 * Footer module is used to render grid content
 * @hidden
 */
var FooterRenderer = /** @class */ (function (_super) {
    __extends(FooterRenderer, _super);
    function FooterRenderer(gridModule, serviceLocator) {
        var _this = _super.call(this, gridModule, serviceLocator) || this;
        _this.aggregates = {};
        _this.parent = gridModule;
        _this.locator = serviceLocator;
        _this.modelGenerator = new SummaryModelGenerator(_this.parent);
        _this.addEventListener();
        return _this;
    }
    /**
     * The function is used to render grid footer div
     */
    FooterRenderer.prototype.renderPanel = function () {
        var div = createElement('div', { className: 'e-gridfooter' });
        var innerDiv = createElement('div', { className: 'e-summarycontent' });
        var movableContent = innerDiv;
        if (this.parent.getFrozenColumns()) {
            var fDiv = createElement('div', { className: 'e-frozenfootercontent' });
            var mDiv = createElement('div', { className: 'e-movablefootercontent' });
            innerDiv.appendChild(fDiv);
            innerDiv.appendChild(mDiv);
            this.frozenContent = fDiv;
            this.movableContent = mDiv;
            movableContent = mDiv;
        }
        if (Browser.isDevice) {
            movableContent.style.overflowX = 'scroll';
        }
        div.appendChild(innerDiv);
        this.setPanel(div);
        if (this.parent.getPager() != null) {
            this.parent.element.insertBefore(div, this.parent.getPager());
        }
        else {
            this.parent.element.appendChild(div);
        }
    };
    /**
     * The function is used to render grid footer table
     */
    FooterRenderer.prototype.renderTable = function () {
        var contentDiv = this.getPanel();
        var innerDiv = this.createContentTable('_footer_table');
        var table = innerDiv.querySelector('.e-table');
        var tFoot = createElement('tfoot');
        table.appendChild(tFoot);
        if (this.parent.getFrozenColumns()) {
            var freezeTable = table.cloneNode(true);
            this.frozenContent.appendChild(freezeTable);
            this.freezeTable = freezeTable;
            this.movableContent.appendChild(table);
            remove(table.querySelector('colgroup'));
            var colGroup = ((this.parent.getHeaderContent().querySelector('.e-movableheader').querySelector('colgroup')).cloneNode(true));
            table.insertBefore(colGroup, table.querySelector('tbody'));
            this.setColGroup(colGroup);
        }
        this.setTable(table);
    };
    FooterRenderer.prototype.renderSummaryContent = function (e, table, cStart, cEnd) {
        var input = this.parent.dataSource instanceof Array ? this.parent.dataSource : this.parent.currentViewData;
        var summaries = this.modelGenerator.getData();
        var dummies = isNullOrUndefined(cStart) ? this.modelGenerator.getColumns() :
            this.modelGenerator.getColumns(cStart, cEnd);
        var rows = isNullOrUndefined(cStart) ? this.modelGenerator.generateRows(input, e || this.aggregates) :
            this.modelGenerator.generateRows(input, e || this.aggregates, cStart, cEnd);
        var fragment = document.createDocumentFragment();
        var rowrenderer = new RowRenderer(this.locator, null, this.parent);
        rowrenderer.element = createElement('TR', { className: 'e-summaryrow' });
        for (var srow = 0, len = summaries.length; srow < len; srow++) {
            var row = rows[srow];
            if (!row) {
                continue;
            }
            var tr = rowrenderer.render(row, dummies);
            fragment.appendChild(tr);
        }
        table.tFoot.appendChild(fragment);
        this.aggregates = e;
    };
    FooterRenderer.prototype.refresh = function (e) {
        if (this.parent.getFrozenColumns()) {
            remove(this.getPanel());
            this.renderPanel();
            this.renderTable();
            this.freezeTable.tFoot.innerHTML = '';
            this.renderSummaryContent(e, this.freezeTable, 0, this.parent.getFrozenColumns());
        }
        this.getTable().tFoot.innerHTML = '';
        this.renderSummaryContent(e, this.getTable(), this.parent.getFrozenColumns());
        // check freeze content have no row case
        if (this.parent.getFrozenColumns()) {
            var frozenDiv = this.frozenContent;
            if (!frozenDiv.offsetHeight) {
                frozenDiv.style.height = this.getTable().offsetHeight + 'px';
            }
        }
        this.onScroll();
    };
    FooterRenderer.prototype.refreshCol = function () {
        // frozen table 
        var mheaderCol;
        var fheaderCol = mheaderCol = this.parent.element.querySelector('.e-gridheader').querySelector('colgroup').cloneNode(true);
        if (this.parent.getFrozenColumns()) {
            mheaderCol = renderMovable(fheaderCol, this.parent.getFrozenColumns());
            this.freezeTable.replaceChild(fheaderCol, this.freezeTable.querySelector('colGroup'));
        }
        this.getTable().replaceChild(mheaderCol, this.getColGroup());
        this.setColGroup(mheaderCol);
    };
    FooterRenderer.prototype.onWidthChange = function (args) {
        this.getColFromIndex(args.index).style.width = formatUnit(args.width);
        if (this.parent.allowResizing && args.module === 'resize') {
            this.updateFooterTableWidth(this.getTable());
        }
    };
    FooterRenderer.prototype.onScroll = function (e) {
        if (e === void 0) { e = { left: this.parent.getContent().firstChild.scrollLeft }; }
        this.getTable().parentElement.scrollLeft = e.left;
    };
    FooterRenderer.prototype.getColFromIndex = function (index) {
        var fCol = this.parent.getFrozenColumns();
        if (fCol && fCol > index) {
            return this.freezeTable.querySelector('colGroup').children[index];
        }
        return this.getColGroup().children[index - fCol];
    };
    FooterRenderer.prototype.columnVisibilityChanged = function () {
        this.refresh();
    };
    FooterRenderer.prototype.addEventListener = function () {
        this.parent.on(colGroupRefresh, this.refreshCol, this);
        this.parent.on(columnWidthChanged, this.onWidthChange, this);
        this.parent.on(scroll, this.onScroll, this);
        this.parent.on(columnVisibilityChanged, this.columnVisibilityChanged, this);
    };
    FooterRenderer.prototype.removeEventListener = function () {
        this.parent.off(colGroupRefresh, this.refreshCol);
        this.parent.off(columnWidthChanged, this.onWidthChange);
        this.parent.off(scroll, this.onScroll);
        this.parent.off(columnVisibilityChanged, this.columnVisibilityChanged);
    };
    FooterRenderer.prototype.updateFooterTableWidth = function (tFoot) {
        var tHead = this.parent.getHeaderTable();
        if (tHead && tFoot) {
            tFoot.style.width = tHead.style.width;
        }
    };
    return FooterRenderer;
}(ContentRender));
export { FooterRenderer };
