import { extend } from '@syncfusion/ej2-base';
import { createElement, isNullOrUndefined } from '@syncfusion/ej2-base';
import { NumericTextBox } from '@syncfusion/ej2-inputs';
import { isEditable, isComplexField, getComplexFieldID, getComplexValue } from '../base/util';
/**
 * `NumericEditCell` is used to handle numeric cell type editing.
 * @hidden
 */
var NumericEditCell = /** @class */ (function () {
    function NumericEditCell(parent) {
        this.parent = parent;
    }
    NumericEditCell.prototype.create = function (args) {
        /* tslint:disable-next-line:no-any */
        var isComplex = !isNullOrUndefined(args.column.field) && isComplexField(args.column.field);
        var complexFieldName = !isNullOrUndefined(args.column.field) && getComplexFieldID(args.column.field);
        return createElement('input', {
            className: 'e-field', attrs: {
                /* tslint:disable-next-line:no-any */
                id: isComplex ? this.parent.element.id + complexFieldName : this.parent.element.id + args.column.field,
                name: isComplex ? complexFieldName : args.column.field, 'e-mappinguid': args.column.uid
            }
        });
    };
    NumericEditCell.prototype.read = function (element) {
        element.blur();
        return element.ej2_instances[0].value;
    };
    NumericEditCell.prototype.write = function (args) {
        var col = args.column;
        var isInline = this.parent.editSettings.mode !== 'Dialog';
        var isComplex = !isNullOrUndefined(args.column.field) && isComplexField(args.column.field);
        var complexFieldName = !isNullOrUndefined(args.column.field) && getComplexFieldID(args.column.field);
        var isAddRow = args.requestType === 'add' || args.row.classList.contains('e-addedrow');
        this.obj = new NumericTextBox(extend({
            value: isComplex && !isAddRow ?
                parseFloat(getComplexValue(args.rowData, args.column.field)) : parseFloat(args.rowData[col.field]),
            enableRtl: this.parent.enableRtl,
            placeholder: isInline ? '' : args.column.headerText,
            enabled: isEditable(args.column, args.requestType, args.element),
            floatLabelType: this.parent.editSettings.mode !== 'Dialog' ? 'Never' : 'Always',
        }, col.edit.params));
        this.obj.appendTo(args.element);
        args.element.setAttribute('name', isComplex ? complexFieldName : args.column.field);
    };
    NumericEditCell.prototype.destroy = function () {
        if (this.obj && !this.obj.isDestroyed) {
            this.obj.destroy();
        }
    };
    return NumericEditCell;
}());
export { NumericEditCell };
