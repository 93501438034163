import { extend, isNullOrUndefined } from '@syncfusion/ej2-base';
import { createElement } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { Query, DataManager, DataUtil } from '@syncfusion/ej2-data';
import { isEditable, getComplexFieldID, getComplexValue, isComplexField } from '../base/util';
import { parentsUntil } from '../base/util';
/**
 * `DropDownEditCell` is used to handle dropdown cell type editing.
 * @hidden
 */
var DropDownEditCell = /** @class */ (function () {
    function DropDownEditCell(parent) {
        //constructor
        this.parent = parent;
    }
    DropDownEditCell.prototype.create = function (args) {
        /* tslint:disable-next-line:no-any */
        var complexFieldName = !isNullOrUndefined(args.column.field) && getComplexFieldID(args.column.field);
        /* tslint:disable-next-line:no-any */
        var isComplex = !isNullOrUndefined(args.column.field) && isComplexField(args.column.field);
        return createElement('input', {
            className: 'e-field', attrs: {
                /* tslint:disable-next-line:no-any */
                id: isComplex ? this.parent.element.id + complexFieldName : this.parent.element.id + args.column.field,
                /* tslint:disable-next-line:no-any */
                name: isComplex ? complexFieldName : args.column.field, type: 'text', 'e-mappinguid': args.column.uid,
            }
        });
    };
    DropDownEditCell.prototype.write = function (args) {
        this.column = args.column;
        var isInline = this.parent.editSettings.mode !== 'Dialog';
        /* tslint:disable-next-line:no-any */
        var complexFieldName = !isNullOrUndefined(args.column.field) && getComplexFieldID(args.column.field);
        var isComplex = !isNullOrUndefined(args.column.field) && isComplexField(args.column.field);
        var isAddRow = args.requestType === 'add' || args.row.classList.contains('e-addedrow');
        this.obj = new DropDownList(extend({
            dataSource: this.parent.dataSource instanceof DataManager ?
                this.parent.dataSource : new DataManager(this.parent.dataSource),
            query: new Query().select(args.column.field), enabled: isEditable(args.column, args.requestType, args.element),
            fields: { value: args.column.field },
            value: isComplex && !isAddRow ?
                getComplexValue(args.rowData, args.column.field) : args.rowData[args.column.field],
            enableRtl: this.parent.enableRtl, actionComplete: this.ddActionComplete.bind(this),
            placeholder: isInline ? '' : args.column.headerText, popupHeight: '200px',
            floatLabelType: isInline ? 'Never' : 'Always', open: this.dropDownOpen.bind(this),
            sortOrder: 'Ascending'
        }, args.column.edit.params));
        this.obj.appendTo(args.element);
        /* tslint:disable-next-line:no-any */
        args.element.setAttribute('name', isComplex ? complexFieldName : args.column.field);
    };
    DropDownEditCell.prototype.read = function (element) {
        return element.ej2_instances[0].value;
    };
    DropDownEditCell.prototype.ddActionComplete = function (e) {
        e.result = DataUtil.distinct(e.result, this.column.isForeignColumn() ? this.column.foreignKeyField : this.column.field, true);
        if (this.column.dataSource) {
            this.column.dataSource.dataSource.json = e.result;
        }
    };
    DropDownEditCell.prototype.dropDownOpen = function (args) {
        var dlgElement = parentsUntil(this.obj.element, 'e-dialog');
        if (!isNullOrUndefined(dlgElement)) {
            var dlgObj = this.parent.element.querySelector('#' + dlgElement.id).ej2_instances[0];
            args.popup.element.style.zIndex = (dlgObj.zIndex + 1).toString();
        }
    };
    DropDownEditCell.prototype.destroy = function () {
        if (this.obj) {
            this.obj.destroy();
        }
    };
    return DropDownEditCell;
}());
export { DropDownEditCell };
