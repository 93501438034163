var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { createElement, EventHandler, remove, Browser } from '@syncfusion/ej2-base';
import { parentsUntil } from '../base/util';
import { ContextMenu } from '@syncfusion/ej2-navigations';
import { CheckBoxFilter } from '../actions/checkbox-filter';
import { isNullOrUndefined, } from '@syncfusion/ej2-base';
import { Internationalization } from '@syncfusion/ej2-base';
import { Query, DataManager, Predicate } from '@syncfusion/ej2-data';
import { Dialog } from '@syncfusion/ej2-popups';
import { DropDownList, AutoComplete } from '@syncfusion/ej2-dropdowns';
import { NumericTextBox } from '@syncfusion/ej2-inputs';
import { RadioButton, CheckBox } from '@syncfusion/ej2-buttons';
import { distinctStringValues } from '../base/util';
import { generateQuery } from '../base/constant';
import { DatePicker } from '@syncfusion/ej2-calendars';
/**
 * @hidden
 * `ExcelFilter` module is used to handle filtering action.
 */
var ExcelFilter = /** @class */ (function (_super) {
    __extends(ExcelFilter, _super);
    /**
     * Constructor for excel filtering module
     * @hidden
     */
    function ExcelFilter(parent, filterSettings, serviceLocator, customFltrOperators) {
        var _this = _super.call(this, parent, filterSettings, serviceLocator) || this;
        _this.customFilterOperators = customFltrOperators;
        _this.isExcel = true;
        return _this;
    }
    ExcelFilter.prototype.getCMenuDS = function (type, operator) {
        var options = {
            number: ['Equal', 'NotEqual', '', 'LessThan', 'LessThanOrEqual', 'GreaterThan',
                'GreaterThanOrEqual', 'Between', '', 'CustomFilter'],
            string: ['Equal', 'NotEqual', '', 'StartsWith', 'EndsWith', '', 'Contains', '', 'CustomFilter'],
        };
        options.date = options.number;
        options.datetime = options.number;
        var model = [];
        for (var i = 0; i < options[type].length; i++) {
            if (options[type][i].length) {
                if (operator) {
                    model.push({
                        text: this.getLocalizedLabel(options[type][i]) + '...',
                        iconCss: 'e-icons e-icon-check ' + (operator === options[type][i] ? '' : 'e-emptyicon')
                    });
                }
                else {
                    model.push({
                        text: this.getLocalizedLabel(options[type][i]) + '...'
                    });
                }
            }
            else {
                model.push({ separator: true });
            }
        }
        return model;
    };
    /**
     * To destroy the filter bar.
     * @return {void}
     * @hidden
     */
    ExcelFilter.prototype.destroy = function () {
        if (this.dlg) {
            this.unwireExEvents();
            _super.prototype.destroy.call(this);
        }
        if (this.cmenu.parentElement) {
            remove(this.cmenu);
        }
    };
    ExcelFilter.prototype.createMenu = function (type, isFiltered, isCheckIcon) {
        var options = { string: 'TextFilter', date: 'DateFilter', datetime: 'DateFilter', number: 'NumberFilter' };
        this.menu = createElement('div', { className: 'e-contextmenu-wrapper' });
        if (this.parent.enableRtl) {
            this.menu.classList.add('e-rtl');
        }
        else {
            this.menu.classList.remove('e-rtl');
        }
        var ul = createElement('ul');
        var icon = isFiltered ? 'e-excl-filter-icon e-filtered' : 'e-excl-filter-icon';
        ul.appendChild(this.createMenuElem(this.getLocalizedLabel('ClearFilter'), isFiltered ? '' : 'e-disabled', icon));
        if (type !== 'boolean') {
            ul.appendChild(this.createMenuElem(this.getLocalizedLabel(options[type]), 'e-submenu', isCheckIcon && this.ensureTextFilter() ? 'e-icon-check' : icon + ' e-emptyicon', true));
        }
        this.menu.appendChild(ul);
    };
    ExcelFilter.prototype.createMenuElem = function (val, className, iconName, isSubMenu) {
        var li = createElement('li', { className: className + ' e-menu-item' });
        li.innerHTML = val;
        li.insertBefore(createElement('span', { className: 'e-menu-icon e-icons ' + iconName }), li.firstChild);
        if (isSubMenu) {
            li.appendChild(createElement('span', { className: 'e-icons e-caret' }));
        }
        return li;
    };
    ExcelFilter.prototype.wireExEvents = function () {
        EventHandler.add(this.dlg, 'mouseover', this.hoverHandler, this);
        EventHandler.add(this.dlg, 'click', this.clickExHandler, this);
    };
    ExcelFilter.prototype.unwireExEvents = function () {
        EventHandler.remove(this.dlg, 'mouseover', this.hoverHandler);
        EventHandler.remove(this.dlg, 'click', this.clickExHandler);
    };
    ExcelFilter.prototype.clickExHandler = function (e) {
        var menuItem = parentsUntil(e.target, 'e-menu-item');
        if (menuItem && this.getLocalizedLabel('ClearFilter') === menuItem.innerText.trim()) {
            this.clearFilter();
            this.closeDialog();
        }
    };
    ExcelFilter.prototype.destroyCMenu = function () {
        if (this.menuObj && !this.menuObj.isDestroyed) {
            this.menuObj.destroy();
            remove(this.cmenu);
        }
    };
    ExcelFilter.prototype.hoverHandler = function (e) {
        var target = e.target.querySelector('.e-contextmenu');
        var li = parentsUntil(e.target, 'e-menu-item');
        var focused = this.menu.querySelector('.e-focused');
        var isSubMenu;
        if (focused) {
            focused.classList.remove('e-focused');
        }
        if (li) {
            li.classList.add('e-focused');
            isSubMenu = li.classList.contains('e-submenu');
        }
        if (target) {
            return;
        }
        if (!isSubMenu) {
            var submenu = this.menu.querySelector('.e-submenu');
            if (!isNullOrUndefined(submenu)) {
                submenu.classList.remove('e-selected');
            }
            this.isCMenuOpen = false;
            this.destroyCMenu();
        }
        var selectedMenu = this.ensureTextFilter();
        if (!this.isCMenuOpen && isSubMenu) {
            li.classList.add('e-selected');
            this.isCMenuOpen = true;
            var menuOptions = {
                items: this.getCMenuDS(this.options.type, selectedMenu ? selectedMenu.replace(/\s/g, '') : undefined),
                select: this.selectHandler.bind(this),
                onClose: this.destroyCMenu.bind(this),
                enableRtl: this.parent.enableRtl,
                beforeClose: this.preventClose
            };
            this.parent.element.appendChild(this.cmenu);
            this.menuObj = new ContextMenu(menuOptions, this.cmenu);
            var client = this.menu.querySelector('.e-submenu').getBoundingClientRect();
            var pos = { top: 0, left: 0 };
            if (Browser.isDevice) {
                var contextRect = this.getContextBounds(this.menuObj);
                pos.top = (window.innerHeight - contextRect.height) / 2;
                pos.left = (window.innerWidth - contextRect.width) / 2;
                this.closeDialog();
            }
            else {
                pos.top = client.top;
                pos.left = this.getCMenuYPosition(this.dlg, this.menuObj);
            }
            this.menuObj.open(pos.top, pos.left);
        }
    };
    ExcelFilter.prototype.ensureTextFilter = function () {
        var selectedMenu;
        var predicates = this.existingPredicate[this.options.field];
        if (predicates && predicates.length === 2) {
            if (predicates[0].operator === 'greaterThanOrEqual' && predicates[1].operator === 'lessThanOrEqual') {
                selectedMenu = 'Between';
            }
            else {
                selectedMenu = 'CustomFilter';
            }
        }
        else {
            if (predicates && predicates.length === 1) {
                this.optrData = this.customFilterOperators[this.options.type + 'Operator'];
                selectedMenu = this.getSelectedText(predicates[0].operator);
            }
        }
        return selectedMenu;
    };
    ExcelFilter.prototype.preventClose = function (args) {
        if (args.event instanceof MouseEvent && args.event.target.classList.contains('e-submenu')) {
            args.cancel = true;
        }
    };
    ExcelFilter.prototype.getContextBounds = function (context) {
        var elementVisible = this.menuObj.element.style.display;
        this.menuObj.element.style.display = 'block';
        return this.menuObj.element.getBoundingClientRect();
    };
    ExcelFilter.prototype.getCMenuYPosition = function (target, context) {
        var contextWidth = this.getContextBounds(context).width;
        var targetPosition = target.getBoundingClientRect();
        var leftPos = targetPosition.right + contextWidth - this.parent.element.clientWidth;
        return (leftPos < 1) ? (targetPosition.right + 1) : (targetPosition.left - contextWidth - 1);
    };
    ExcelFilter.prototype.openDialog = function (options) {
        var _this = this;
        this.updateModel(options);
        this.getAndSetChkElem(options);
        this.showDialog(options);
        this.dialogObj.dataBind();
        var filterLength = (this.existingPredicate[options.field] && this.existingPredicate[options.field].length) ||
            this.options.filteredColumns.filter(function (col) {
                return _this.options.field === col.field;
            }).length;
        this.createMenu(options.type, filterLength > 0, (filterLength === 1 || filterLength === 2));
        this.dlg.insertBefore(this.menu, this.dlg.firstChild);
        this.dlg.classList.add('e-excelfilter');
        this.dlg.classList.remove('e-checkboxfilter');
        this.cmenu = createElement('ul', { className: 'e-excel-menu' });
        this.wireExEvents();
    };
    ExcelFilter.prototype.closeDialog = function () {
        _super.prototype.closeDialog.call(this);
    };
    ExcelFilter.prototype.selectHandler = function (e) {
        if (e.item) {
            this.menuItem = e.item;
            this.renderDialogue(e);
        }
    };
    ExcelFilter.prototype.renderDialogue = function (e) {
        var _this = this;
        var target = e.element;
        var column = this.options.field;
        var mainDiv = createElement('div', { className: 'e-xlfl-maindiv', id: column + '-xlflmenu' });
        this.dlgDiv = createElement('div', { className: 'e-xlflmenu', id: column + '-xlfldlg' });
        this.parent.element.appendChild(this.dlgDiv);
        this.dlgObj = new Dialog({
            header: this.getLocalizedLabel('CustomFilter'),
            isModal: true,
            overlayClick: this.removeDialog.bind(this),
            showCloseIcon: true,
            closeOnEscape: false,
            target: document.body,
            // target: this.parent.element,
            visible: false,
            enableRtl: this.parent.enableRtl,
            open: function () {
                var row = _this.dlgObj.element.querySelector('table.e-xlfl-table>tr');
                row.cells[1].querySelector('input:not([type=hidden])').focus();
            },
            close: this.removeDialog.bind(this),
            created: this.createdDialog.bind(this, target, column),
            buttons: [{
                    click: this.filterBtnClick.bind(this, column),
                    buttonModel: {
                        content: this.getLocalizedLabel('OKButton'), isPrimary: true, cssClass: 'e-xlfl-okbtn'
                    }
                },
                {
                    click: this.removeDialog.bind(this),
                    buttonModel: { content: this.getLocalizedLabel('CancelButton'), cssClass: 'e-xlfl-cancelbtn' }
                }],
            content: mainDiv,
            width: 430,
            animationSettings: { effect: 'None' },
        });
        this.dlgObj.appendTo(this.dlgDiv);
    };
    ExcelFilter.prototype.removeDialog = function () {
        this.removeObjects([this.dropOptr, this.datePicker, this.actObj, this.numericTxtObj, this.dlgObj]);
        remove(this.dlgDiv);
    };
    ExcelFilter.prototype.clearBtnClick = function (field) {
        this.clearFilter();
        this.removeDialog();
    };
    ExcelFilter.prototype.createdDialog = function (target, column) {
        this.renderCustomFilter(target, column);
        this.dlgObj.element.style.left = '0px';
        this.dlgObj.element.style.top = '0px';
        if (Browser.isDevice && window.innerWidth < 440) {
            this.dlgObj.element.style.width = '90%';
        }
        this.dlgObj.show();
    };
    ExcelFilter.prototype.renderCustomFilter = function (target, column) {
        var dlgConetntEle = this.dlgObj.element.querySelector('.e-xlfl-maindiv');
        /* tslint:disable-next-line:max-line-length */
        var dlgFields = createElement('div', { innerHTML: this.getLocalizedLabel('ShowRowsWhere'), className: 'e-xlfl-dlgfields' });
        dlgConetntEle.appendChild(dlgFields);
        //column name
        var fieldSet = createElement('div', { innerHTML: this.options.displayName, className: 'e-xlfl-fieldset' });
        dlgConetntEle.appendChild(fieldSet);
        this.renderFilterUI(column, dlgConetntEle);
    };
    ExcelFilter.prototype.filterBtnClick = function (col) {
        var fValue = this.dlgDiv.querySelector('#' + col + '-xlfl-frstvalue').ej2_instances[0];
        var fOperator = this.dlgDiv.querySelector('#' + col + '-xlfl-frstoptr').ej2_instances[0];
        var sValue = this.dlgDiv.querySelector('#' + col + '-xlfl-secndvalue').ej2_instances[0];
        var sOperator = this.dlgDiv.querySelector('#' + col + '-xlfl-secndoptr').ej2_instances[0];
        var checkBoxValue;
        if (this.options.type === 'string') {
            var checkBox = this.dlgDiv.querySelector('#' + col + '-xlflmtcase').ej2_instances[0];
            checkBoxValue = checkBox.checked;
        }
        var andRadio = this.dlgDiv.querySelector('#' + col + 'e-xlfl-frstpredicate').ej2_instances[0];
        var orRadio = this.dlgDiv.querySelector('#' + col + 'e-xlfl-secndpredicate').ej2_instances[0];
        var predicate = (andRadio.checked ? 'and' : 'or');
        if (sValue.value === null) {
            predicate = 'or';
        }
        this.filterByColumn(this.options.field, fOperator.value, fValue.value, predicate, checkBoxValue, this.parent.filterSettings.ignoreAccent, sOperator.value, sValue.value);
        this.removeDialog();
    };
    /**
     * Filters grid row by column name with given options.
     * @param {string} fieldName - Defines the field name of the filter column.
     * @param {string} firstOperator - Defines the first operator by how to filter records.
     * @param {string | number | Date | boolean} firstValue - Defines the first value which is used to filter records.
     * @param  {string} predicate - Defines the relationship between one filter query with another by using AND or OR predicate.
     * @param {boolean} matchCase - If ignore case set to true, then filter records with exact match or else
     * filter records with case insensitive(uppercase and lowercase letters treated as same).
     * @param {boolean} ignoreAccent - If ignoreAccent set to true, then ignores the diacritic characters or accents when filtering.
     * @param {string} secondOperator - Defines the second operator by how to filter records.
     * @param {string | number | Date | boolean} secondValue - Defines the first value which is used to filter records.
     */
    ExcelFilter.prototype.filterByColumn = function (fieldName, firstOperator, firstValue, predicate, matchCase, ignoreAccent, secondOperator, secondValue) {
        var _this = this;
        var col = this.parent.getColumnByField(fieldName);
        var field = col.isForeignColumn() ? col.foreignKeyValue : fieldName;
        var fColl = [];
        var mPredicate;
        fColl.push({
            field: field,
            predicate: predicate,
            matchCase: matchCase,
            ignoreAccent: ignoreAccent,
            operator: firstOperator,
            value: firstValue,
            type: this.options.type
        });
        mPredicate = new Predicate(field, firstOperator.toLowerCase(), firstValue, !matchCase, ignoreAccent);
        if (secondValue) {
            secondOperator = !isNullOrUndefined(secondOperator) ? secondOperator : 'equal';
            fColl.push({
                field: field,
                predicate: predicate,
                matchCase: matchCase,
                ignoreAccent: ignoreAccent,
                operator: secondOperator,
                value: secondValue,
                type: this.options.type
            });
            /* tslint:disable-next-line:max-line-length */
            mPredicate = mPredicate[predicate](field, secondOperator.toLowerCase(), secondValue, !matchCase, ignoreAccent);
        }
        var args = {
            action: 'filtering', filterCollection: fColl, field: this.options.field,
            ejpredicate: mPredicate, actualPredicate: fColl
        };
        var fPredicate = {};
        var filterCollection = [];
        if (col.isForeignColumn()) {
            this.options.column.dataSource.
                executeQuery(new Query().where(mPredicate)).then(function (e) {
                _this.options.column.columnData = e.result;
                _this.parent.notify(generateQuery, { predicate: fPredicate, column: col });
                args.ejpredicate = fPredicate.predicate.predicates;
                fPredicate.predicate.predicates.forEach(function (fpred) {
                    filterCollection.push({
                        field: fpred.field,
                        predicate: 'or',
                        matchCase: fpred.ignoreCase,
                        ignoreAccent: fpred.ignoreAccent,
                        operator: fpred.operator,
                        value: fpred.value,
                        type: _this.options.type
                    });
                });
                args.filterCollection = filterCollection.length ? filterCollection :
                    fColl.filter(function (col) { return col.field = _this.options.field; });
                _this.options.handler(args);
            });
        }
        else {
            this.options.handler(args);
        }
    };
    /* tslint:disable-next-line:max-line-length */
    ExcelFilter.prototype.renderOperatorUI = function (column, table, elementID, predicates, isFirst) {
        var fieldElement = createElement('tr', { className: 'e-xlfl-fields' });
        table.appendChild(fieldElement);
        var xlfloptr = createElement('td', { className: 'e-xlfl-optr' });
        fieldElement.appendChild(xlfloptr);
        var optrDiv = createElement('div', { className: 'e-xlfl-optrdiv' });
        var optrInput = createElement('input', { id: column + elementID });
        optrDiv.appendChild(optrInput);
        xlfloptr.appendChild(optrDiv);
        var optr = this.options.type + 'Operator';
        var dropDatasource = this.customFilterOperators[optr];
        this.optrData = dropDatasource;
        var selectedValue = this.dropSelectedVal(column, predicates, isFirst);
        //Trailing three dots are sliced.
        var menuText = '';
        if (this.menuItem) {
            menuText = this.menuItem.text.slice(0, -3);
            if (menuText !== this.getLocalizedLabel('CustomFilter')) {
                selectedValue = isFirst ? menuText : undefined;
            }
            if (menuText === this.getLocalizedLabel('Between')) {
                selectedValue = this.getLocalizedLabel(isFirst ? 'GreaterThanOrEqual' : 'LessThanOrEqual');
            }
        }
        this.dropOptr = new DropDownList({
            dataSource: dropDatasource,
            fields: { text: 'text', value: 'value' },
            text: selectedValue,
            open: this.dropDownOpen.bind(this),
            enableRtl: this.parent.enableRtl
        });
        this.dropOptr.appendTo(optrInput);
        var operator = this.getSelectedValue(selectedValue);
        return { fieldElement: fieldElement, operator: operator };
    };
    ExcelFilter.prototype.dropDownOpen = function (args) {
        args.popup.element.style.zIndex = (this.dialogObj.zIndex + 1).toString();
    };
    ExcelFilter.prototype.getSelectedValue = function (text) {
        var selectedField = new DataManager(this.optrData).executeLocal(new Query().where('text', 'equal', text));
        return !isNullOrUndefined(selectedField[0]) ? selectedField[0].value : '';
    };
    ExcelFilter.prototype.dropSelectedVal = function (col, predicates, isFirst) {
        var operator;
        if (predicates && predicates.length > 0) {
            operator = predicates.length === 2 ?
                (isFirst ? predicates[0].operator : predicates[1].operator) :
                (isFirst ? predicates[0].operator : undefined);
        }
        else {
            operator = isFirst ? 'equal' : undefined;
        }
        return this.getSelectedText(operator);
    };
    ExcelFilter.prototype.getSelectedText = function (operator) {
        var selectedField = new DataManager(this.optrData).executeLocal(new Query().where('value', 'equal', operator));
        return !isNullOrUndefined(selectedField[0]) ? selectedField[0].text : '';
    };
    ExcelFilter.prototype.renderFilterUI = function (column, dlgConetntEle) {
        var predicates = this.existingPredicate[column];
        var table = createElement('table', { className: 'e-xlfl-table' });
        dlgConetntEle.appendChild(table);
        var colGroup = createElement('colGroup');
        colGroup.innerHTML = '<col style="width: 50%"></col><col style="width: 50%"></col>';
        table.appendChild(colGroup);
        //Renders first dropdown
        /* tslint:disable-next-line:max-line-length */
        var optr = this.renderOperatorUI(column, table, '-xlfl-frstoptr', predicates, true);
        //Renders first value
        this.renderFlValueUI(column, optr, '-xlfl-frstvalue', predicates, true);
        var predicate = createElement('tr', { className: 'e-xlfl-predicate' });
        table.appendChild(predicate);
        //Renders first radion button
        this.renderRadioButton(column, predicate, predicates);
        //Renders second dropdown
        optr = this.renderOperatorUI(column, table, '-xlfl-secndoptr', predicates, false);
        //Renders second text box
        this.renderFlValueUI(column, optr, '-xlfl-secndvalue', predicates, false);
    };
    ExcelFilter.prototype.renderRadioButton = function (column, tr, predicates) {
        var td = createElement('td', { className: 'e-xlfl-radio', attrs: { 'colSpan': '2' } });
        tr.appendChild(td);
        var radioDiv = createElement('div', { className: 'e-xlfl-radiodiv', attrs: { 'style': 'display: inline-block' } });
        /* tslint:disable-next-line:max-line-length */
        var frstpredicate = createElement('input', { id: column + 'e-xlfl-frstpredicate', attrs: { 'type': 'radio' } });
        /* tslint:disable-next-line:max-line-length */
        var secndpredicate = createElement('input', { id: column + 'e-xlfl-secndpredicate', attrs: { 'type': 'radio' } });
        //appends into div
        radioDiv.appendChild(frstpredicate);
        radioDiv.appendChild(secndpredicate);
        td.appendChild(radioDiv);
        if (this.options.type === 'string') {
            this.renderMatchCase(column, tr, td, '-xlflmtcase', predicates);
        }
        // Initialize AND RadioButton component.
        /* tslint:disable-next-line:max-line-length */
        var andRadio = new RadioButton({ label: this.getLocalizedLabel('AND'), name: 'default', cssClass: 'e-xlfl-radio-and', checked: true, enableRtl: this.parent.enableRtl });
        // Initialize OR RadioButton component.
        /* tslint:disable-next-line:max-line-length */
        var orRadio = new RadioButton({ label: this.getLocalizedLabel('OR'), name: 'default', cssClass: 'e-xlfl-radio-or', enableRtl: this.parent.enableRtl });
        var flValue = predicates && predicates.length === 2 ? predicates[1].predicate : 'and';
        if (flValue === 'and') {
            andRadio.checked = true;
            orRadio.checked = false;
        }
        else {
            orRadio.checked = true;
            andRadio.checked = false;
        }
        // Render initialized RadioButton.
        andRadio.appendTo(frstpredicate);
        orRadio.appendTo(secndpredicate);
    };
    /* tslint:disable-next-line:no-any */
    ExcelFilter.prototype.removeObjects = function (elements) {
        for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
            var obj = elements_1[_i];
            if (obj && !obj.isDestroyed) {
                obj.destroy();
            }
        }
    };
    /* tslint:disable-next-line:max-line-length */
    ExcelFilter.prototype.renderFlValueUI = function (column, optr, elementId, predicates, isFirst) {
        var value = createElement('td', { className: 'e-xlfl-value' });
        optr.fieldElement.appendChild(value);
        var valueDiv = createElement('div', { className: 'e-xlfl-valuediv' });
        var valueInput = createElement('input', { id: column + elementId });
        valueDiv.appendChild(valueInput);
        value.appendChild(valueDiv);
        var flValue;
        var predicate;
        if (predicates && predicates.length > 0) {
            predicate = predicates.length === 2 ?
                (isFirst ? predicates[0] : predicates[1]) :
                (isFirst ? predicates[0] : undefined);
            flValue = (predicate && predicate.operator === optr.operator) ? predicate.value : undefined;
        }
        var types = {
            'string': this.renderAutoComplete.bind(this),
            'number': this.renderNumericTextBox.bind(this),
            'date': this.renderDate.bind(this),
            'datetime': this.renderDate.bind(this)
        };
        types[this.options.type](this.options, column, valueInput, flValue, this.parent.enableRtl);
    };
    /* tslint:disable-next-line:max-line-length */
    ExcelFilter.prototype.renderMatchCase = function (column, tr, matchCase, elementId, predicates) {
        /* tslint:disable-next-line:max-line-length */
        var matchCaseDiv = createElement('div', { className: 'e-xlfl-matchcasediv', attrs: { 'style': 'display: inline-block' } });
        var matchCaseInput = createElement('input', { id: column + elementId, attrs: { 'type': 'checkbox' } });
        matchCaseDiv.appendChild(matchCaseInput);
        matchCase.appendChild(matchCaseDiv);
        var flValue = predicates && predicates.length > 0 ?
            (predicates && predicates.length === 2 ? predicates[1].matchCase : predicates[0].matchCase) :
            false;
        // Initialize Match Case check box.
        var checkbox = new CheckBox({ label: this.getLocalizedLabel('MatchCase'),
            enableRtl: this.parent.enableRtl, checked: flValue });
        // Render initialized CheckBox.
        checkbox.appendTo(matchCaseInput);
    };
    /* tslint:disable-next-line:max-line-length */
    ExcelFilter.prototype.renderDate = function (options, column, inputValue, fValue, isRtl) {
        var intl = new Internationalization();
        var format = intl.getDatePattern({ type: 'date', skeleton: options.format }, false);
        this.datePicker = new DatePicker({
            format: format,
            cssClass: 'e-popup-flmenu',
            placeholder: this.getLocalizedLabel('CustomFilterDatePlaceHolder'),
            width: '100%',
            enableRtl: isRtl,
            value: new Date(fValue),
        });
        this.datePicker.appendTo(inputValue);
    };
    ExcelFilter.prototype.completeAction = function (e) {
        e.result = distinctStringValues(e.result);
    };
    /* tslint:disable-next-line:max-line-length */
    ExcelFilter.prototype.renderNumericTextBox = function (options, column, inputValue, fValue, isRtl) {
        this.numericTxtObj = new NumericTextBox({
            format: options.format,
            placeholder: this.getLocalizedLabel('CustomFilterPlaceHolder'),
            enableRtl: isRtl,
            value: fValue
        });
        this.numericTxtObj.appendTo(inputValue);
    };
    /* tslint:disable-next-line:max-line-length */
    ExcelFilter.prototype.renderAutoComplete = function (options, column, inputValue, fValue, isRtl) {
        var _this = this;
        var colObj = this.parent.getColumnByField(column);
        var isForeignColumn = colObj.isForeignColumn();
        var dataSource = isForeignColumn ? colObj.dataSource : options.dataSource;
        var fields = { value: isForeignColumn ? colObj.foreignKeyValue : column };
        var actObj = new AutoComplete({
            dataSource: dataSource instanceof DataManager ? dataSource : new DataManager(dataSource),
            fields: fields,
            query: this.parent.query.clone(),
            sortOrder: 'Ascending',
            locale: this.parent.locale,
            autofill: true,
            focus: function () {
                actObj.filterType = _this.dlgDiv.querySelector('#' + column +
                    (inputValue.id === (column + '-xlfl-frstvalue') ?
                        '-xlfl-frstoptr' :
                        '-xlfl-secndoptr')).ej2_instances[0].value;
                actObj.ignoreCase = options.type === 'string' ?
                    !_this.dlgDiv.querySelector('#' + column + '-xlflmtcase').ej2_instances[0].checked :
                    true;
                actObj.filterType = !isNullOrUndefined(actObj.filterType) ? actObj.filterType :
                    'equal';
            },
            placeholder: this.getLocalizedLabel('CustomFilterPlaceHolder'),
            enableRtl: isRtl,
            actionComplete: function (e) {
                e.result = e.result.filter(function (obj, index, arr) {
                    return arr.map(function (mapObject) {
                        return mapObject[actObj.fields.value];
                    }).indexOf(obj[_this.actObj.fields.value]) === index;
                });
            },
            value: fValue
        });
        actObj.appendTo(inputValue);
        this.actObj = actObj;
    };
    /**
     * For internal use only - Get the module name.
     * @private
     */
    ExcelFilter.prototype.getModuleName = function () {
        return 'excelFilter';
    };
    return ExcelFilter;
}(CheckBoxFilter));
export { ExcelFilter };
